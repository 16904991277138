import config from "@/config"

//系统路由
const routes = [
	{
		name: "layout",
		path: "/",
		component: () => import(/* webpackChunkName: "layout" */ '@/layout'),
		redirect: config.DASHBOARD_URL || '/dashboard',
		children: []
	},
	{
		path: "/login",
		component: () => import(/* webpackChunkName: "login" */ '@/views/login'),
		meta: {
			title: "登录"
		}
	},
	{
		path: "/user_register",
		component: () => import(/* webpackChunkName: "userRegister" */ '@/views/login/userRegister'),
		meta: {
			title: "注册"
		}
	},
	{
		path: "/reset_password",
		component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/login/resetPassword'),
		meta: {
			title: "重置密码"
		}
	},
	
]

export default routes;



////系统路由
//const routes = [{
//	name: "layout",
//	path: "/",
//	component: () => import(/* webpackChunkName: "layout" */ '@/layout'),
//	redirect: '/Information',
//	children: [
//		{
//			name: "home",
//			path: "/home",
//			component: () => import(`@/views/other/empty`),
//			meta: {
//				title: "首页",
//				icon: "el-icon-s-home"
//			},
//			children: [
//				{
//					name: "Information",
//					path: "/Information",
//					meta: {
//						title: "金翊资讯",
//						icon: "iconfont icon-xinwenzixun",
//						affix: true
//					},
//					component: () => import(/* webpackChunkName: "home" */ '@/views/knowledge/Information'),
//				},
//				{
//					name: "dashboard",
//					path: "/dashboard",
//					meta: {
//						title: "控制台",
//						icon: "el-icon-menu",
//					},
//					component: () => import(/* webpackChunkName: "home" */ '@/views/home'),
//				},
//				// {
//				// 	name: "exchange_rate",
//				// 	path: "/exchange_rate",
//				// 	meta: {
//				// 		title: "汇率转换",
//				// 		icon: "iconfont icon-exchangerate",
//				// 	},
//				// 	component: () => import(/* webpackChunkName: "home" */ '@/views/knowledge/exchange_rate'),
//				// },
//				{
//					name: "userCenter",
//					path: "/usercenter",
//					meta: {
//						title: "个人信息",
//						icon: "el-icon-user",
//					},
//					component: () => import(/* webpackChunkName: "usercenter" */ '@/views/userCenter'),
//				}
//				,
//				{
//					name: "xxrw",
//					path: "/setup/newbietask",
//					meta: {
//						title: "意见管理页面",
//						icon: "el-icon-user",
//						hidden: true
//					},
//					component: () => import('@/views/setup/newbietask'),
//				}
//				, {
//					name: "xsjc",
//					path: "/setup/novicetutorial",
//					meta: {
//						title: "意见管理",
//						icon: "el-icon-user",
//						hidden: true
//					},
//					component: () => import('@/views/setup/novicetutorial'),
//				}
//				, {
//					name: "versionUpdate",
//					path: "/setup/versionupdate",
//					meta: {
//						title: "版本更新",
//						icon: "el-icon-user",
//						hidden: true
//					},
//					component: () => import('@/views/setup/versionupdate'),
//				}
//			]
//		}
//	]
//},
//{
//	path: "/cmd",
//	component: () => import(/* webpackChunkName: "cmd" */ '@/views/other/cmd'),
//	meta: {
//		title: "CMD"
//	}
//},
//{
//	path: "/login",
//	component: () => import(/* webpackChunkName: "login" */ '@/views/login/'),
//	meta: {
//		title: "登录"
//	}
//}
//	// ,{
//	// 	path: "/versionupdate",
//	// 	component: () => import(/* webpackChunkName: "login" */ '@/views/versionupdate/versionupdate'),
//	// 	meta: {
//	// 		title: "版本更新"
//	// 	}
//	// }
//]

//export default routes;
