import config from "@/config"
import http from "@/utils/request"

export default {
	menu: {
		myMenus: {
			url: `${config.API_URL}/RoleManage/getRoleMenus`,
			name: "登录获取菜单-内部",
			post: async function(params){
				return await http.post(this.url,params);
			}
		},
		getPERMISSIONS:{
			url: `${config.API_URL}/RoleManage/getPERMISSIONS`,
			name: "获取我的按钮权限",
			get: async function(params){
				return await http.get(this.url,params);
			}
		},
		myMenusOut: {
			url: `${config.API_URL}/RoleManage/getRoleMenusOut`,
			name: "登录获取菜单-外部",
			post: async function(params){
				return await http.post(this.url,params);
			}
		},
		myRoleMenus: {
			url: `${config.API_URL}/Role/getRoleMenus`,
			name: "角色管理中获取某个角色的菜单列表",
			get: async function(params){			
				return await http.get(this.url,params);
			}
		},
		myRoleMenus1: {
			url: `${config.API_URL}/Role/GetRoleMenusList2`,
			name: "角色管理中获取某个角色的菜单列表",
			get: async function(params){			
				return await http.get(this.url,params);
			}
		},
		permissionMenu : {
			url: `${config.API_URL}/RoleManage/permissionMenu`,
			name: "角色管理/权限分配-菜单（全部）-读取",
			get: async function(){
				return await http.get(this.url);
			}
		},
		permissionMenuChecked: {
			url: `${config.API_URL}/RoleManage/permissionMenuChecked`,
			name: "角色管理/权限分配-菜单(已勾选）-读取",
			get: async function(params){
				return await http.get(this.url,params);
			}
		},
		permissionMenuCheckedSave: {
			url: `${config.API_URL}/RoleManage/permissionMenuCheckedSave`,
			name: "角色管理/权限分配-菜单(已勾选）-保存",
			post: async function(params){
				return await http.post(this.url,params);
			}
		},
		list: {
			url: `${config.API_URL}/system/menu/list`,
			name: "获取菜单",
			get: async function(){
				return await http.get(this.url);
			}
		}
	},
	//流程审批角色列表
	dept: {
		list: {
			url: `${config.API_URL}/workflow/RoleTreeList`,
			name: "获取部门列表",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		}
	},
	dic: {
		tree: {
			url: `${config.API_URL}/system/dic/tree`,
			name: "获取字典树",
			get: async function(){
				return await http.get(this.url);
			}
		},
		list: {
			url: `${config.API_URL}/system/dic/list`,
			name: "字典明细",
			get: async function(params){
				return await http.get(this.url, params);
			}
		},
		get: {
			url: `${config.API_URL}/system/dic/get`,
			name: "获取字典数据",
			get: async function(params){
				return await http.get(this.url, params);
			}
		}
	},
	role1: {
		list: {
			url: `${config.API_URL}/UserManage/banguser`,
			name: "获取角色下拉",
			get: async function(params){
				return await http.get(this.url, params);
			}
		}
	},
	role: {
		list: {
			url: `${config.API_URL}/workflow/RoleTreeList`,
			name: "获取角色列表",
			post: async function(params){
				return await http.post(this.url, params);
			}
		}
	},
	user: {
		list: {
			url: `${config.API_URL}/UserManage/userlist`,
			name: "获取用户列表",
			post: async function(params){
				return await http.post(this.url, params);
			}
		}
		,list1: {
			url: `${config.API_URL}/UserManage/userlist1`,
			name: "获取用户列表",
			post: async function(params){
				return await http.post(this.url, params);
			}
		}
	},
	app: {
		list: {
			url: `${config.API_URL}/system/app/list`,
			name: "应用列表",
			get: async function(){
				return await http.get(this.url);
			}
		}
	},
	log: {
		list: {
			url: `${config.API_URL}/system/log/list`,
			name: "日志列表",
			get: async function(params){
				return await http.get(this.url, params);
			}
		}
	}
}
