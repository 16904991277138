import config from "@/config"
import http from "@/utils/request"

export default {
	//流程节点，审核人
	getFlowGrant: {
		url: `${config.API_URL}/workflow/getFlowGrant`,
		name: "流程节点，审核人",
		post: async function (data) {
			return await http.post(this.url, data);
		}
	},
	post: {
		url: `${config.API_URL}/UserManage/Adduser`,
		name: "分页列表",
		post: async function (data) {
			return await http.post(this.url, data, {
				headers: {
					//'response-status': 401
				}
			});
		}
	},
	post1: {
		url: `${config.API_URL}/Role/Addrole`,
		name: "分页列表",
		post: async function (data) {
			return await http.post(this.url, data, {
				headers: {
					//'response-status': 401
				}
			});
		}
	},
	//流程审批用户列表
	page: {
	
		url: `${config.API_URL}/workflow/UserTreeList`,
		name: "分页列表",
		post: async function (params) {
			return await http.post(this.url, params);
		}
	},
	menu: {
		url: `${config.API_URL}/demo/menu`,
		name: "普通用户菜单",
		get: async function () {
			return await http.get(this.url);
		}
	},
	menuAdd: {
		url: `${config.API_URL}/Setting/fmsAddMenu`,
		name: "菜单管理-添加菜单",
		post: async function (data) {
			return await http.post(this.url, data, {
				headers: {
					//'response-status': 401
				}
			});
		}
	},
	menuUpdate: {
		url: `${config.API_URL}/Setting/fmsUpdateMenu`,
		name: "菜单管理-按钮-批量修改",
		post: async function (data) {
			return await http.post(this.url, data, {
				headers: {
					//'response-status': 401
				}
			});
		}
	},
	tableAdd: {
		url: `${config.API_URL}/Setting/fmsAddTable`,
		name: "表格列管理-按钮-添加",
		post: async function (data) {
			return await http.post(this.url, data, {
				headers: {
					//'response-status': 401
				}
			});
		}
	},
	tableUpade: {
		url: `${config.API_URL}/Setting/fmsUpadeTable`,
		name: "表格列管理-按钮-批量修改",
		post: async function (data) {
			return await http.post(this.url, data, {
				headers: {
					//'reponse-status':401
				}
			});
		}
	},
	tableFieldSynchronization: {
		url: `${config.API_URL}/Setting/fmsFieldSynchronizationTable`,
		name: "表格列管理-按钮-字段同步",
		post: async function (data) {
			return await http.post(this.url, data, {
				headers: {
					//'reponse-status':401
				}
			});
		}
	},
	//目的港
	portOfArrivalInfo: {
		url: `${config.API_URL}/PortofArrival/fmsGetPortOfArrival1`,
		name: "目的港",
		post: async function (params) {
			return await http.post(this.url, params);
		}
	},
	//用户
	userInfo: {
		url: `${config.API_URL}/UserManage/GetUser`,
		name: "用户",
		post: async function (params) {
			return await http.post(this.url, params);
		}
	},
	//漫游引导
	tourall:{		
		tourInfo: {
			url: `${config.API_URL}/Tour/GetTourInfo`,
			name: "漫游引导查询",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		updtourInfo: {
			url: `${config.API_URL}/Tour/UpdTourInfo`,
			name: "漫游引导修改状态",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		updTourInfoMenu: {
			url: `${config.API_URL}/Tour/UpdTourInfoMenu`,
			name: "漫游引导修改菜单名称",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
	}

}
