export default {
	login: {//登录
		Suggestion: '建议：先清缓存，再登录',
		UserName: '用户名',
		PassWord: '密码',
		accountLogin: '账号登录',
		LogIn: '登录',
		ClearCache: '清缓存',
		qsryhm: '请输入用户名/手机号/邮箱',
		qsrmm: '请输入密码',
		CreateNewAccount:'创建新账号',
		noAccount:'还没有账号?',
		forgetPassword:'忘记密码?',
	},
	bar: {//导航栏
		homes: '首页',
		jyzx: "金翊资讯",
		kzt: "控制台",
		grxx: "个人信息",
		cgshzcx: "船公司汇总查询",
	},
	user: {
		dynamic: '动态',
		info: '个人信息',
		settings: '设置',
		nightmode: '黑夜模式',
		nightmode_msg: '适合光线较弱的环境，当前黑暗模式为beta版本',
		language: '语言',
		language_msg: '翻译进行中，暂翻译了本视图的文本',
		lcsp: '流程审批',
	},
	userbar: { //标题栏
		CLogistics: '金翊物流APP',
		WorkNotice: '工作通知',
		zwxx: '暂无新消息',
		Renovate: '刷新',
		qbbjyd: '全部标记已读',
		gyssh: '供应商审核',
		Supplier: '供应商',
		zwxxx: '暂无新消息',
		gysxx: '供应商消息',
		NewNews: '新消息',
		Unaudited: '未审核',
		Returned: '已退回',
		yjcn: '意见采纳',
		bbgx: '版本更新',
		grsz: '个人设置',
		tcdl: '退出登录',
	},
	Information: {//首页-金翊资讯
		whpj: "外汇牌价",
		qzzgyh: "取自中国银行发布数据，仅供参考",
		qbzx: "全部资讯",
		xhmrj: "现汇买入价"
	},
	Process: {//流程审批
		fkzy: '付款摘要',
		fkje: '付款金额',
		fkfs: '付款方式',
		hdmc: '货代名称',
		hdzq: '货代账期',
		jysf: '金翊收费',
		ddbh: '订单编号',
		lczt: '流程状态',
		qqbt: '请求标题',
		dqjd: '当前节点',
		dqzt: '当前状态',
		lcmb: '流程模板',
		appendix: '附件',
		title: '标题',
		order_id: '流程编号',
		proposer: '申请人',
		proposer_time: '申请时间',
		forwarder_name: '客户名称',
		trade_clause: '贸易条款',
		pi: 'PI',
		shipping_time: '出运时间',
		cyfs: '出运方式',
		cyhw: '出运货物',
		comment: '评论',
		tj: '体积',
		print: '打印',
		shipping_name: '订单名称',
		money_account: '打款账号',
		scfj: '上传附件',
		bank_payment_voucher: '银行付款凭证',
		id: '序号',
		cost_name: '费用名称',
		payment_amount: '发票金额',
		invoice_number: '发票号码',
		currency: '币种',
		remark: '备注',
		spjd: '审批进度',
		Return: '退回',
		Pass: '通过',
		thly: '退回理由',
		Account: '理由',
		Cancel: '取消',
		Define: '确定',
		Supplier: '供应商',
		Jinyicustomer_name: '金翊业务',
		amount_of_lossCNY: '预计金额人民币',
		amount_of_lossUSD: '预计金额美金',
		end_state: '完结状态',
		update_time: '更新时间',
		description_errors: '工作失误描述',
		responsibility_distinction: '责任划分',
		solution: '解决方案',
		gysshwb: '供应商审核（外部）',
		name: '个人名称',
		post: '岗位',
		enterprise_name: '企业名称',
		phone: '联系电话',
		mailbox: '联系邮箱',
		address: '详细地址',
		yyzz: '营业执照',
		smsjxz: '手机扫码下载（仅限安卓）',
		thyy: '退回原因',
		lcmc: '流程名称',
		jxlc: '新建流程',
		cxfq: '重新发起',
		xjspjd: '新建审批节点',

		delete: '删除',
		all: '全部',
		pending: '待处理',
		csw: '抄送我',
		qxz: '请选择',
		Check: '查询',
		Resetting: '重置',
		Revoke: '撤销',
	},
	freight_rates: {//运价管理-整箱运费查询
		Por: '目的港',
		carrier: '船公司',
		line: '航线',
		country: '国家',
		freight_rate: '运价',
		transshipment: '转运',
		sailing_Date: '航程',
		wharf: '码头',
		effectity: '有限期开始',
		validity: '有效期结束',
		remark: '备注',
		surcharge: '附加费',
		weight_limitation: '限重',
		other: '其他',
		dESTINATION_TERMINAL: '目的港码头',
		destination_surcharge: '目的港附加费',
		yfgxjl: '运费更新记录',
		update_user: '上传人',
		update_time: '上传时间',
		qsrmdg: '请输入目的港',
		zjgx: '最近更新',
		jlscyy: '距离上次已有',
		wgx: '未更新',
		qbscsj: '全部删除数据',
		ckgxjl: '查看更新记录',
		Check: '查询',
		import: '导入',
		Resetting: '重置',
		supplier_name: '供应商',
	},
	freighCalculation: {//运价管理-国内物流运费计算
		gmwljs: '国内物流运费计算',
		destination: '目的地：',
		qxzmdd: '请选择目的地',
		volume: '体积：',
		qsrtj: '请输入体积(立方米)',
		longs: '长',
		widths: '宽',
		heights: '高',
		size: '尺寸',
		actualWeight: '实际重量:',
		sqssjzl: '请输入实际重量',
		billingWeights: '计费重量:',
		calculate: '计算',
		Startinglocation: '起始地点：',
		shanghai: '上海',
		aging: '运输时效：',
		minCharge: '基础运费：',
		finalCost: '最终运费：',
		day: '天'
	},
	quotation: { //报价管理
		bjxzr: '报价选择人',
		CreationTime: '创建时间',
		InitiateQuotation: '发起报价',
		id: '编号',
		externalQuotation_id: '外部报价编号',
		state: '报价状态',
		confirms: '状态',
		customer: '客户名',
		phone: '客户电话',
		email: '客户邮箱',
		pol: '起运港',
		pod: '目的港',
		trade_clauseId: '贸易条款',
		carrier: '承运方',
		box_box: '箱型箱量',
		weight: '毛重',
		volume: '体积',
		voyage: '航程',
		exchangeRate: '汇率',
		etd: '预计离港日',
		create_user: '创建人',
		create_time: '创建时间',
		update_user: '修改人员',
		Remake: '备注',
		applicationTime: '申请时间',
		Applicant: '申请人',
		name: '申请人姓名:',
		department: '申请人部门:',
		applicantPhone: '申请人电话:',
		applicantEmail: '申请人邮箱:',
		address: '目的地地址',
		y_remarks: '业务备注',
		c_remarks: '船务备注',
		consignment_type: '托运类型',
		goback: '订单状态',
		bjid: '报价编号',
		BidderTime: '报价时间',
		quote_information: '报价信息',
		Bidder: '报价人',
		BidderPhone: '报价人电话',
		BidderEmail: '报价人邮箱',
		to_customer: '客户名称',
		number: '件数',
		transport: '运输方式',
		actual_value_goods: '实际货值',
		taxes: '税金',
		Operation: '操作',
		AddRows: '添加行数',
		xfbgkybjdwbtx: '下方表格可编辑项都为必填项',
		qxz: '请选择',
		ShipperInformation: '发货人信息',
		RecipientInformation: '收货人信息',
		fhrxxhshrxx: '(发货人信息=通知人，收货人信息=收货人 转集运订单时需要，可以手动修改)',
		PriceC: '价格(CNY)',
		PriceU: '价格(USD)',
		LCL: '拼箱',
		FCL: '整箱',
		startTime: '开始日期',
		endTime: '结束日期',
		to: '至',
		all: '全部',
		qsrgkmc: '请输入目的港',
		xzrq: '选择日期',
		jyqf: '集运区分',
		qxzysfs: '请选择运输方式',

		Quoted:'已报价',
		returned:'已退回',
		NotQuoted:'未报价',
		khyqr:'客户已确认',
		yxd:'已下单',
		wxd:'未下单',
		productName:'品名',
		yjfhsj:'预计发货时间',
		boxType:'箱型',
		zmz:'总毛重',
		totalVolume:'总体积',
		ystk:'运输条款',
		fhdz:'发货地址',
		chrdz:'收货人地址',
		zzjyddlx:'转至集运订单类型',
		Remarks:'备注',

		Save: '保存',
		Delete: '删除',
		CopySave: '复制保存',
		Update: '修改',
		Obsolete: '作废',
		Copy: '复制',
		Check: '查询',
		Resetting: '重置',
		Develop: '展开',
		ReturnToModify: '退回修改',
		excelTableExport: 'excel表格导出',
		Return: '退回',
		Quote: '报价',
		Cancel: '取消',
		ConsolidationOrder: '转集运订单',

	},
	Port_of_Arrival: {//目的港设置
		id: '序号',
		cnName: '港口中文',
		enName: '港口英文',
		portCode: '港口代码',
		country: '所属国家',
		enCountry: '国家英文',
		sevenContinentsName: '所属航线',
		drmdg: '导入目的港',

		Develop: '展开',
		Check: '查询',
		Resetting: '重置',
	},
	progress_sc: {//出运安排
		id: '序号',
		pi: 'PI号',
		order_name: '订单名称',
		freight_shipping_way: '货物出运方式',
		freight_shipping_status: '货物出运状态',
		freight_count: '货物件数',
		freight_net_weight: '货物净重(KG)',
		freight_gross_weight: '货物毛重(KG)',
		freight_ton: '货物体积(M³)',
		freight_size: '货物尺寸(CM)',
		freight_ok_date: '货好日期',
		storage_remark: '备注',
		create_user: '创建人',
		create_time: '创建时间',
		salesman: '业务员',
		attachment_tag: '附件是否上传标记',
		pictrue: '图片',

		Develop: '展开',
		Check: '查询',
		Resetting: '重置',
	},
	ShippingDisplay: {//出运安排-出运显示
		id: '序号',
		orderName: '订单名称',
		Business: '所属业务',
		pi: 'PI号',
		issend: '发送状态',
		Dimensions: '尺寸（制造/内径/外径）',
		quantity: '数量',
		boxType: '盒型',
		color: '颜色',
		surfaceTreatment: '表面处理',
		CorrugatedDirection: '瓦楞方向',
		AdhesivePosition: '粘边位置',
		packagingRequirements: '包装要求',
		deliveryDate: '交货期',
		freightForwarder: '货代',
		ReceiptStatus: '收款情况',
		contactPerson: '联系人',
		orderRequirements: '订单要求',

		sDate: '日期',
		SCheckDate: '审核日期',
		SLastEditDate: '最后修改日期',
		SBillerNo: '制单人编码	',
		SBillerName: '制单人名称',
		SChkNo: '审核人编码',
		SChkName: '审核人名称',
		SDeptNo: '部门编码',
		SDeptName: '部门名称',
		SEmpNo: '业务员编码',
		SEmpName: '业务员名称',
		SCustNo: '客户编码',
		SCustName: '客户名称',
		SCurrNo: '币别编码',
		SCurrName: '币别名称',
		SCurrRate: '汇率',
		C_13823755841: '预估托盘尺寸',
		C_24078915534: '是否确认',
		C_639328797626: '新老客户',
		Remake: '备注',
		sEntryID: '行号',
		is_produce: '是否已下发生产',
		sItemNo: '物料代码',
		sItemName: '物料名称	',
		sItemModel: '规格',
		sPropNo: '属性编码',
		sPropName: '属性名称',
		sBatchNo: '批次号',
		sSerialNo: '序列号',
		sUnitNo: '计量单位编码',
		sUnit: '计量单位',
		sPrice0: '折前单价',
		sAmt0: '折前金额',
		sDiscRate: '折扣率',
		sDiscAmt: '折扣额',
		sPrice: '实际单价',
		sAmt: '实际金额',
		sTaxRate: '税率',
		sTaxPrice: '实际含税单价',
		sTax: '税额',
		sAllAmt: '价税合计',
		sUnit2No: '辅助计量单位编码',
		sUnit2: '辅助计量单位',
		SUnitRate: '换算率',
		sQty: '数量',
		sQty2: '辅助计量单位数据',
		sEntryNote: '行备注',
		c_14961262566: '出运备注',
		jyqf: '集运区分',
		qxzysfs: '请选择运输方式',
		xzczr: '选择操作人',
		fsdd: '发送订单',

		Save: '保存',
		Cancel: '取消',
		Develop: '展开',
		Check: '查询',
		Resetting: '重置',
	},
	Consolidation: {//集运订单
		id: '序号',
		PINumber: 'PI号',
		CustomerName: '客户名',
		OrderName: '订单名称',
		CreationTime: '创建时间',
		OrderNumber: '订单编号',
		Requester: '委托单位',
		BookingAgent: '订舱代理',
		Bidder: '报价人',
		DeliveryTime: '发货时间',
		TermsOfTrade: '贸易条款',
		PolicyNumber: '保单号',
		destination_port: '目的港',
		consignee: '收货人',
		Inquire: '查询',
		Add: '新增',
		MoreOperations: '更多操作',
		CopyOrder: '复制订单',
		ExportLedger: '导出台账',
		ExportAllLedgers: '导出全部台账',
		End: '完结',
		CancelEnd: '取消完结',
		Label: '标签',
		Locking: '锁定',
		Unlock: '解锁',
		Delete: '删除',
		Reset: '重置',
		Expand: '展开',
		DeclarationTitle: '报关抬头',
		CustomsClearance: '报关',
		FactoryCar: '厂车',
		QuantityOfOrder: '订单数量',
		BookingRemarks: '订舱备注',
		MasterOrderNumber: '主单号',
		ShipName: '船名',
		BoxVolume: '箱型箱量',
		ShippingCompany: '船公司',
		Por: '起运港',
		Pod: '目的港',
		CutOffTime: '截单时间',
		ClosingTime: '截关时间',
		ExchangeTime: '换单时间',
		BusinessPeople: '业务人员',
		ConsignmentType: '托运类型',
		ServiceType: '服务类型',
		BargeETD: '驳船ETD',
		ForeignAgent: '国外代理',
		ActualTotalVolume: '实际总体积',
		ActualGrossWeight: '实际总毛重',
		ActualTotalNumberOfPieces: '实际总件数',
		Value: '货值',
		PaymentMethod: '付款方式',
		BargeName: '驳船船名',
		BargeVoyage: '驳船航次',
		SONumber: 'SO号',
		CaseNumber: '箱号',
		Founder: '创建人',
		Pier: '码头',
		ReceivableWriteOffTime: '应收核销时间',
		ReceivableWriteOffStatus: '应收核销状态',
		ChineseName: '中文品名',
		PlaceOfDelivery: '交货地',
		Destination: '目的地',
		Route: '航线',
		BillOfLadingForm: '提单形式',
		NetWeight: '净重',
		PaymentAddress: '付款地址',
		AdditionalConditions: '附加条件',
		DateOfIssue: '签发日期',
		IssueAddress: '签发地址',
		OverseasShippingAddress: '海外送货地址',
		PortOfDischarge: '卸货港',
		ShippingAgent: '船代',
		Premium: '保费',
		TransitPortCode: '中转港代码',
		StateName: '状态名',
		StatusExit: '状态退关',
		StatusComplete: '状态完结',
		StateTime: '状态时间',
		StateImportAndExport: '状态进出口',
		Voyage: '航次',
		CargoMarking: '货主箱标记',
		BargePort: '驳船港',
		Sender: '发货人',
		Receiver: '收货人',
		order_type: '订单类型',
		Mark: '唛头',
		EnglishProductName: '英文品名',
		TermsOfCarriage: '运输条款',
		EstimatedShippingTime: '预计出货时间',
		FirstNotifier: '第一通知人',
		SecondNotifier: '第二通知人',
		ActualNumber: '实发个数',
		BySea: '海运',
		BoxGoods: '箱货',
		WhereIsTheBoat: '船在哪儿',
		Number: '件数',
		Weight: '毛重',
		Volume: '体积',
		AlertOthers: '通知人',
		BillOfLadingRemarks: '提单备注',
		RemarksForMultipleProductNames: '多品名备注',
		CabinRemarks: '配舱备注',
		OperationNotes: '操作备注',
		EventName: '活动名称',
		BusinessInformation: '业务信息',
		DistributionInformation: '配舱信息',
		BillOfLadingInformation: '提单信息',
		InsideInformation: '内部信息',
		OrderStatus: '< 订单状态',
		goback: '订单状态',
		Save: '保存',
		sfcd: '是否转送',
		ExportDocuments: '导出单证',
		CargoManagement: '箱货管理',
		DeliveryPicture: '发货图片',
		AbnormalSituation: '异常情况',
		CustomsInformation: '报关资料',
		CustomsDeclaration: '报关单',
		ReleaseSheet: '放行单',
		Download: '下载',
		CustomsClearanceInformation: '清关资料',
		Telephone: '电放件',
		UploadData: '上传资料',
		ExpenseEntry: '费用录入',
		ToAir: '转为空运',
		ToGround: '转为陆运',
		ToRail: '转为铁运',
		ToUSPS: '转为跨境包裹',
		AddBoxCapacity: '新增箱型箱量',
		BillOfLadingNumber: '提单号',
		Airline: '航空公司',
		FlightNumber: '航班号',
		VolumeToWeightRatio: '体积重比例',
		VolumeWeight: '体积重',
		SubBubbleWeight: '分泡重',
		BillableWeight: '计费重量',
		BubbleWeight: '泡重',
		ShippingStation: '上货站',
		UnloadingStation: '下货站',
		RoundTrip: '往返',
		PortOfEntryAndExit: '出入境口岸',
		TrainNumber: '班列号',
		BrainDate: '班列日期',
		QuoteNumber: '报价编号',
		SpecialRequest: '特殊要求',
		OrderTime: '接单时间',
		SingleNumber: '单号',
		PickupAddress: '提货地址',
		PickupTime: '提货时间',
		ShippingAddress: '发货地址',
		TransportationRemarks: '运输委托备注',
		OrderRemarks: '订单备注',
		ExpressTrackingNumber: '快递单号',
		Boxing: '装箱',
		Tray: '托盘',
		EstimatedTax: '预估税金',
		ActualTax: '实际税金',
		ShippingWarehousing: '运输/仓储',
		StartDate: '开始日期',
		EndDate: '结束日期',
		To: '至',
		qxz: '请选择',
		qxzfkfs: '请选择付款方式',
		qxzrq: '选择日期',
		qxzwtdw: '请选择委托单位',
		qxzdcdl: '请选择订舱代理',
		qxzbjr: '请选择报价人',
		qxzhkgs: '请选择航空公司',
		qxzhzxbj: '请选择货主箱标记',
		qxzhx: '请选择航线',
		qxztdxs: '请选择提单形式',
		qxzsjcfsj: '选择实际出发时间',
		qxzmytk: '请选择贸易条款',
		FileName: '文件名称',
		FileType: '文件类型',
		Cancel: '取消',
		Export: '导出',
		jwjtdcc: '将文件拖到此处，或点击上传',
		qgzlqr: '清关资料确认',
		qxzbgtt: '请选择报关抬头',
		qxzcgs: '请选择船公司',
		LCL: '拼箱',
		Supplier: '供应商',
		Default: '默认',
		AddALabel: '新增标签',
		LabelType: '标签类型',
		TagName: '标签名',
		xzbgd: '下载报关单',
		xzyc: '新增异常',
		dfjcflb: '电放件存放列表',
		xzzl: '下载资料',
		qxzxxxx: '请选择箱型箱量',
		Box: '箱型',
		ContainerVolume: '箱量',
		BoxNumber: '箱封/号',
		AddBoxType: '+添加箱型',
		ExceptionFlag: '异常标记',
		SplitBubbleRatio: '分泡比例',
		djxzxflb: '点击下方列表即可下载',
		BasicInformation: '基础信息',
		UploadWaterBill: '上传水单',
		UploadInvoice: '上传发票',
		ExportBill: '导出账单',
		PaymentRequest: '付款申请',
		BusinessType: '业务类型',
		BillAttribute: '账单属性',
		USDollarCurrency: '美金汇率',
		Attributes: '属性',
		SettlementUnit: '结算单位',
		FeeName: '费用名称',
		Currency: '币种',
		BillAmount: '账单金额',
		InvoiceNumber: '发票号',
		xjjt: '采购',
		bjjt: '报价',
		xjjtfile: '采购文件',
		bjjtfile: '报价文件',
		FeeStatus: '费用状态',
		EntryPerson: '录入人',
		LastModifiedBy: '最后修改人',
		LastModified: '最后修改时间',
		Operate: '操作',
		CurrentExchangeRate: '当前汇率',
		Receivable: '应收',
		Handle: '应付',
		Ticket: '票',
		Total: '合计',
		TotalGrossProfit: '毛利合计',
		RMBEquivalent: '折合人民币',
		Profit: '利润',
		Quantity: '数量',
		UnitPrice: '单价',
		Update: '修改',
		Bill: '发票',
		Submit: '提交',
		payment_amount: '发票金额',
		remark: '备注',
		title: '标题',
		order_id: '流程编号',
		proposer: '申请人',
		proposer_time: '申请时间',
		forwarder_name: '客户名称',
		shipping_time: '出运时间',
		money_account: '打款账号',
		scfj: '上传附件',
		ApplicationDate: '申请日期',
		qxzzdsx: '请选择账单属性',
		khddzl: '客户订单资料',
		khzl: '客户资料',
		contract: '合同',
		schschqdjsfbq: '上传成功或已删除后请再次点击上方 标签',
		contractfile: '合同文件',
		customerinformation: '客户信息',
		customerinformationfile: '客户信息文件',
		psd: '评审单',
		psdfile: '评审单文件',
		producttp: '产品图片',
		producttpfile: '产品图片文件',
		jmt: '件毛体',
		jmtfile: '件毛体文件',
		//报关 拖车
		electronic_commission:'电子委托',
		qztjfjcscwts:'请在添加附近出上传委托书',
		exit_customs:'出境关别',
		customs_at_the_place_of_declaration:'申报地海关',
		port_of_departure:'离境口岸',
		domestic_shipper:'境内发货人',
		social_credit_code:'社会信用代码',
		customs_code:'海关编码',
		overseas_consignee:'境外收货人',
		aeo_customs_code:'AEO海关编码',
		production_and_sales_unit:'生产销售单位',
		transportation_method:'运输方式',
		means_of_transport:'运输工具',
		voyage:'航次',
		waybill_number:'提运单号',
		ship_name_voyage:'船名航次',
		supervision_method:'监管方式',
		nature_of_tax_exemption:'征免性质',
		permit_number:'许可证号',
		contract_agreement_number:'合同协议号',
		trading_country:'贸易国',
		shipping_port:'指运港',
		arrive:'运抵国',
		transaction_method:'成交方式',
		freight:'运费',
		shipping_amount:'金额',
		shipping_currency:'币制',
		premium:'保费',
		miscellaneous_expenses:'杂费',
		packaging_type:'包装种类',
		number_of_pieces:'件数',
		gross_weight:'毛重',
		net_weight:'净重',
		mark_code:'标记唛码',
		special_relationship:'特殊关系',
		affect_price:'影响价格',
		royalties:'特许权使用费',
		order_code:'订单号',
		product_item_number:'项号',
		product_number:'商品编号',
		product_name:'商品名称',
		specifications_and_models:'规格型号',
		the_number_of_transactions:'成交数量',
		transaction_unit:'成交单位',
		total_price:'总价',
		currency:'币制',
		quorum:'法定数量',
		legal_unit:'法定单位',
		domestic_source_of_goods:'境内货源地',
		country_of_origing:'原产国',
		second_quantity:'第二数量',
		second_unit:'第二单位',
		final_destination_country:'最终目的国',
		taxation_and_exemption_methods:'征免方式',
		container_name:'集装箱号',
		specification:'规格',
		lcl_remark:'拼箱',
		allproduct_item_number:'商品管理项号',
		accompanying_documents:'随附单证',
		document_number:'单证编号',
		bcddfs:'保存到待发送',
		bcdcgx:'保存到草稿箱',
		bgdlr:'报关单录入',
		chxx:'船货信息',
		hwxq:'货物详情',
		jzxxx:'集装箱信息',
		sfdzxx:'随附单证信息',
		chaxun:'查询',
		suoqi:'缩起',
		zhankai:'展开',
		luru:'录入',
		plfs:'批量发送',
		baocun:'保存',
		ddxx:'订单信息',
		bill_of_lading_number:'提单号',
		shipping_company:'船公司',
		port_of_loading:'装货港',
		ship_name:'船名',
		port_of_discharge:'卸货港',
		contact_person:'联系人',
		contact_phone:'联系电话',
		box_box:'箱型箱量',
		destination:'目的港',
		container_loading_address:'装柜地址',
		loading_time:'装柜时间',
		xgbc:'修改保存',
		box_num:'箱量',
		box_type:'箱型',
		queren:'确认',
		quxiao:'取消',
		scyp:'上传预配',
		cz:'操作',
		sc:'删除',
		fuzhicopy:'复制',
		chakan:'查看',
		bgd:'报关单',
		fxd:'放行单',
		bgwtxy:'报关委托协议',
		schdd:'生成核对单',		
		tjfj:'添加附件',		
		xzqbfj:'下载全部附件',		
		qingxuanze:'请选择',		
		fuzhifahuoren:'复制发货人',		
		qsr:'请输入',		
		zwsj:'暂无数据',
		djzjbz:'单价/总价/币制',
		heji:'合计',
		cjsl:'成交数量',
		fdsl:'法定数量',
		desl:'第二数量',
		zonjia:'总价',
		swsdspbh:'十位数的商品编号',
		qb:'全部',
		gdxx:'更多信息',
		kuajingFahuofangshi:'发货方式',
	},
	cost: {//费用管理
		Revoke: '撤销核销',
		ddbh: '订单编号',
		title: '标题',
		proposer: '申请人',
		proposer_time: '申请时间',
		forwarder_name: '客户名称',
		pi: 'PI号',
		shipping_name: '订单名称',
		id: '序号',
		cost_name: '费用名称',
		invoice_number: '发票号',
		currency: '币种',
		remark: '备注',
		Return: '退回',
		Pass: '通过',
		thly: '退回理由',
		Cancel: '取消',
		Define: '确定',
		Supplier: '供应商',
		Jinyicustomer_name: '金翊业务',
		amount_of_lossCNY: '预计金额人民币',
		amount_of_lossUSD: '预计金额美金',
		end_state: '完结状态',
		update_time: '更新时间',
		description_errors: '工作失误描述',
		responsibility_distinction: '责任划分',
		solution: '解决方案',
		post: '岗位',
		qxz: '请选择',
		Check: '查询',
		Resetting: '重置',
		WriteOffAmount: '核销金额',
		ExchangeRate: '汇率',
		Income: '收入',
		Expenditure: '支出',
		Profit: '利润',
		RMBPayable: '人民币应付',
		HandlingFee: '手续费',
		WriteOffTime: '核销时间',
		WriteOffMethod: '核销方式',
		CancelAccount: '核销账号',
		WriteOffRemarks: '核销备注',
		CostAttribute: '费用属性',
		FeeStatus: '费用状态',
		ReconciliationStatus: '对账状态',
		ReconciliationTime: '对账时间',
		SettlementUnit: '结算单位',
		ConsolidationType: '集运类型',
		Bidder: '报价人',
		Currency: '币种',
		DeliveryTime: '发货时间',
		BusinessType: '业务类型',
		CreationTime: '创建时间',
		YearMonth: '年月',
		VAT: '增值税',
		AdditionalTax: '附加税',
		CorporateIncomeTax: '企业所得税',
		StampDuty: '印花税',
		SalesFee: '销售费用(平台)',
		SalesFeeAdvertising: '销售费用(广告宣传)',
		AdministrativeExpensesFits: '管理费用(员工福利)',
		AdministrativeExpenses: '管理费用(办公费)',
		Total: '合计',
		Time: '时间',
		Years: '年份',
		Add: '新增',
		ImportOtherCosts: '导入其他成本',
		ExportOtherCosts: '导出其他成本',
		ImportJinyiTax: '导入金翊税金',
		ExportJinyiTax: '导出金翊税金',
		ExportBill: '导出账单',
		ExportAllBills: '导出全部账单',
		BatchWriteOff: '批量核销',
		BatchStarReconciliation: '批量对账',
		Delete: '删除',
		ProcessSubmission: '流程提交',
		Update: '修改',
		BillAmount: '账单金额',
		Clerk: '业务员',
		PaidInRatio: '实收比例',
		Attributes: '属性',
		ApplicantDate: '申请人日期',
		WriteoffCurrency: '核销币种',
		State: '状态',
		Save: '保存',
		qxzfylx: '请选择费用属性',
		qxzywlx: '请选择业务类型',
		qxzjsdw: '请选择结算单位',
		qxzbz: '请选择币种',
		qxzhxfs: '请选择核销方式',
		StartDate: '开始日期',
		EndDate: '结束日期',
		To: '至',
		yxwxthlgssj: '注：以下为依据系统汇率估算数据，仅供参考',
		plhxsmjhrmbyfkhx: '温馨提醒：批量核销时美金和人民币要分开核销',
		WrittenOff: '已核销',
		NotWrittenOff: '未核销',
		Strip: '条',
		Sum: '总合',
		AccountNumber: '账单号',
		FinancialDocumentNumber: '财务凭证号',
		BillNotes: '账单注释',
		Receivable: '应收',
		Invoice: '发票抬头',
		TotalAmount: '合计金额',
		AmountNotWrittenOff: '未核销金额',
		TheAmount: '金额',
		TaxRate: '税率(%)',
		ExpensesNotWrittenOff: '费用未核销',
		Requester: '委托单位',
		Tax: '税金',
		PriceWithoutTax: '不含税价',
		TotalPriceInLocalCurrency: '折本币总价',
		BillNumber: '账单编号',
		WriteOff: '核销',
		ViewWaterBill: '查看水单',
		ViewInvoice: '查看发票',
		Reconciliation: '对账',
		BasicInformation: '基础信息',
		ReceivableDetails: '应收明细',
		WriteOffRecords: '核销记录',
		Handle: '应付',
		PayableDetails: '应付明细',
		Name: '姓名',
		Month: '月份',
		GrossProfit: '毛利',
		ColinCommission: '柯林分佣',
		Commission: '提成',
		CommissionRemarks: '提成备注',
		Export: '导出',
		ImportSalary: '导入工资',
		ExportSalary: '导出工资',
		Department: '部门',
		EmployeeSalary: '员工工资',
		TotalWages: '工资合计',
		ImportFiveInsurancesAndOneHousingFund: '导入五险一金',
		ExportFiveInsurancesAndOneHousingFund: '导出五险一金',
		BasicEndowmentInsuranceForUrbanWorkers: '城镇职工基本养老保险',
		BasicMedicalInsuranceForEmployees: '职工基本医疗保险',
		UnemploymentInsurance: '失业保险',
		InjuryInsurance: '工伤保险',
		LocalAdditionalMedicalInsurance: '地方附加医疗保险',
		ProvidentFund: '公积金',
		TotalGrossProfit: '总毛利 ',
		TotalRebate: '总分佣 ',
		TotalCommission: '总提成',
		TotalProvidentFund: '公积金合计',
		TotalSocialSecurity: '社保合计',
		StartMonth: '开始月份',
		endMonth: '结束月份',
		qxzgw: '请选择岗位',
		qxzwtdw: '请选择委托单位',
	},
	customerManage: {//客户管理
		Username: '用户名',
		CustomerAddress: '客户地址',
		Nation: '国别',
		BossName: '老板名称',
		Relationway: '联系方式',
		CompanyRemark: '公司备注',
		PurchaseIntention: '采购意向',
		PurchaseAmount: '年采购额',
		TransactionCurrency: '交易币种',
		MeansofPayments: '客户结算方式',
		CustomerGrade: '客户星级',
		CustomerStage: '客户状态',
		SocialPlatformAccount: '社交平台账号',
		CustomerSource: '客户来源',
		PhoneNumber: '手机号',
		Mail: '邮箱',
		Nature: '性质',
		BelongsToTheBusiness: '所属业务',
		SocialUnifiedCreditCode: '社会统一信用代码',
		AccountPeriod: '账期',
		SettlementMethod: '结算方式',
		ReconciliationMethod: '对账方式',
		TypeOfEnterprise: '企业类型',
		Add: '新增',
		Edit: '编辑',
		BusinessLicense: '营业执照',
		CompanyName: '企业名称',
		Address: '详细地址',
		CustomerName: '客户名称',
		CustomerPhone: '客户电话',
		CustomerEmail: '客户邮箱',
		title1:'跟进日期设定',
		currenttime:'初始日期',
		settingtraget:'设定目标值',
		targettime:'目标日期',
		enableorno:'是否启用',
		TypeOfCompany: '公司类型',
		FileLicenseInformation: '档案执照信息',
		UploadBusinessLicense: '上传营业执照',
		CustomerBusinessLicense: '客户营业执照',
		FileName: '文件名称',
		FileType: '文件类型',
		Category: '类别',
		CreationTime: '创建时间',
		Founder: '创建人',
		Operate: '操作',
		Update: '修改',
		NewEmail: '填写新电话',
		NewPhone: '填写新邮箱',
		cxwbtx: '此为必填项',
		ViewImage: '查看图片',
		follow_up_dynamics:'跟进动态',
		djckdt:'点击查看动态',
		tjdt: '添加动态',
		jcxx: '基础信息 ',
		tzxx: '特征信息',
		lxrxx: '联系人信息',
	}, 
	supplier: {//供应商管理
		TotalProvidentFund: '公积金合计',
		Add: '新增',
		Status: '状态',
		Addtag: '添加标签',
		BatchOperation: '批量操作',
		Blacklist: '黑名单',
		ToDelete: '向删除',
		ToRecycleBin: '向回收站',
		RecycleBin: '回收站',
		Audit: '审核',
		WasReturnedToTheSupplier: '被退回供应商',
		CommonTags: '常用搜索',
		Expand: '展开',
		CompanyLetterhead: '公司抬头',
		ReconciliationMethod: '对账方式',
		SettlementMethod: '结算方式',
		Nature: '性质',
		Nation: '国家',
		AccountPeriod: '账期',
		CreationTime: '创建时间',
		Founder: '创建人',
		ProvinceState: '州/省',
		ChineseAddress: '中文地址',
		EnglishName: '英文名称',
		BillingCycleKey: '结算周期钥',
		Operator: '操作人员',
		CompanyBillingAddress: '公司账单地址',
		Type: '类型',
		Country: 'Country国家',
		CityCity: 'City城市',
		SocialUnifiedCreditCode: '社会统一信用代码',
		Address: '详细地址',
		EnglishAddress: '英文地址',
		BusinessPeople: '业务人员.',
		Save: '保存',
		Cancel: '取消',
		Check: '查询',
		Delete: '删除',
		Sure: '确定',
		Reset: '重置',
		Update: '修改',
		AddToBlacklist: '加入黑名单',
		ConvertToForeignAgent: '转为国外代理',
		Label: '标签',
		SupplierInformationAttachment: '供应商信息附件',
		BasicInformation: '基础信息',
		ContactInformation: '联系方式',
		GeneralInformation: '常用信息',
		SupplierRemarks: '供应商备注',
		AddContactInformation: '添加联系方式',
		AddEnglishProductName: '添加英文品名',
		AddMark: '添加唛头',
		CooperationStatus: '合作状态',
		BillingCycleMonthly: '结算周期 每月',
		startTime: '开始日期',
		endTime: '结束日期',
		to: '至',
		qsrbq:'请输入标签',
		qsrzq:'请输入账期',
		qxz:'请选择',
		restore:'还原',
		bjck:'编辑查看',
		operation:'操作',
		id:'序号',
		tagName:'标签名',
		LabelType:'标签类型',
		AddNewLabel:'新增标签',
		default:'默认',
		Supplier:'供应商',
		qsrts:'请输入天数',
		Area:'区域',
		srgysgjzss:'输入供应商关键字搜索',
		BusinessLicense:'营业执照',
		grmc:'个人名称',
		qymc:'企业名称',
		Position:'岗位',
		Time:'时间',
		lxdh:'联系电话',
		lxyx:'联系邮箱',
		scxz:'上传限制（条数）',
		Creator:'创建人',
		Name: '姓名',
	},
	setting: {//配置管理
		ID: '序号',
		UserName: '用户名',
		Name: '姓名',
		Department: '部门',
		Mail: '邮箱',
		Post: '岗位',
		State: '状态',
		Operate: '操作',
		Search: '搜索',
		AddUser: '添加用户',
		RoleDescription: '角色描述',
		RoleName: '角色名称',
		DirectSuperior: '直接上级',
		IndirectSuperior: '间接上级',
		CharacterName: '角色名',
		Edit: '编辑',
		Add: '新增',
		Cancel: '取消',
		Check: '查询',
		Save: '保存',
		Delete: '删除',
		Sure: '确定',
		Reset: '重置',
		Update: '修改',
		PermissionAssignment: '权限分配',
		AddRole: '添加角色',
		UploadTemplate: '上传模板',
		jwjtccc: '将文件拖到此处，或点击上传',
		FileName: '文件名称',
		Download: '下载',
		MenuName: '菜单名称',
		MenuNameEn: '菜单英文名称',
		ParentNode: '父级节点',
		RoutingAddress: '路由地址',
		MenuIcon: '菜单图标',
		ComponentAddress: '组件地址',
		Type: '类型',
		Hide: '隐藏',
		Sort: '排序',
		Field: '字段',
		ModuleName: '模块名称',
		TableName: '表格名称',
		Width: '宽度',
		Fixed: '固定',
		FieldOrder: '字段顺序',
		jyzgnbtc: '金翊主管内部提成率',
		jyzgwbtc: '金翊主管外部提成率',
		dztc: '单证提成率',
		klcztcl: '柯林操作提成率',
		jyxswbjc: '金翊销售外部基础提成率',
		jyxswblb: '金翊销售外部两倍底薪提成率',
		jyxssb: '金翊销售三倍底薪提成率',
		jyxsnb: '金翊销售内部利润率',
		ColinCommissionRate: '柯林分佣率',
		jywbtc: '金翊外部提成总额',
		jynbtc: '金翊内部提成总额',
		bdswjl: 'BD商务经理利润',
		TaxRatio: '税金比例',
		USDollarCurrency: '美金汇率',
		Years: '年月',
		BusinessName: '业务名称',
		BasicSalary: '底薪',
		Founder: '创建人',
		CreationTime: '创建时间',
		LastModifiedBy: '最后修改人',
		LastModified: '最后修改时间',
		Base: '基础',
		TableColumn: '表格列',
		BatchEdit: '批量修改',
		Logo: '标识',
		EnglishName: '英文名称',
		AssigningRoles: '分配角色',
		FieldSync: '字段同步',
		MenuPermissions: '菜单权限',
		RolePermissionSettings: '角色权限设置',
		UserLoginName: '用户登录名',
		LoginPassword: '登录密码',
		Password: '密码',
		Telephone: '电话',
		HeaderName: '表头名称',
		NoData: '暂无数据',
		RoleType: '角色类型',
		qsr: '请输入',
		qsryhm: '请输入用户名',
		qsrxm: '请输入姓名',
		qsrbm: '请输入部门',
		qsrcdmc: '请输入菜单名称',
		qsrmkmc: '请输入模块名称',
		qsrbgmc: '请输入表格名称',
		qsrbtmc: '请输入表头名称',
		qsrwybsl: '请输入唯一标识',
		qsrzdsx: '请输入字段顺序',
		qsrkd: '请输入宽度',
		qsrywmc: '请输入英文名称',
		qsrmc: '请输入名称',
		qsrzwmsbt: '请输入中文描述标题',
		qsrzd: '请输入字段',
		qxzjslx: '请选择角色类型',
		user_area: '角色类型',
	}, knowledge: {//金翊
		Edit: '编辑',
		Add: '添加',
		Cancel: '取消',
		Check: '查询',
		Save: '保存',
		Delete: '删除',
		Sure: '确定',
		Reset: '重置',
		Update: '修改',
		Develop: '展开',
		TitleName: '标题名称',
		Category: '类别',
		FileName: '文件名称',
		FileCategory: '文件类别 ',
		FilePath: '文件路径',
		FileSize: '文件大小 ',
		CreationTime: '创建时间',
		VideoTitle: '视频标题',
		PictureName: '图片名称',
		ImageSize: '图片大小',
		VideoName: '视频名称',
		VideoCategory: '视频类别',
		VideoPath: '视频路径',
		VideoSize: '视频大小',
		ShippingTreasureBox: '船务百宝箱',
		ShippingKnowledge: '海运小知识',
		ReferenceDocument: '参考文件',
		ExpandKnowledge: '扩展知识',
		WebsiteName: '网站名称',
		WebsiteAddress: '网站地址',
		WebsiteLink: '网站链接',
		WebsiteCover: '网站封面',
		VideoCover: '视频封面',
		VideoUpload: '视频上传',
		UploadPicture: '图片上传',
		InformationCover: '资讯封面',
		FileUpload: '文件上传',
		qbkc: '全部课程',
		zxkc: '最新课程',
		More: '更多',
		jwjtdcc: '将文件拖到此处，或<em>点击上传</em>',
		zbschpg: '只能上传jpg/png文件，且不超过500kb',
		znscpdf: '只能上传pdf文件',
		znscMP4: '只能上传MP4文件',
		znscpdfxlsx: '只能上传.pdf和xlsx文件',
	}, reportcenter: {//报表中心
		sjlysl: '商机来源数量',
		zchddsl: '总成交订单数量',
		sjly: '商机来源',
		xsgczb: '销售过程指标',
		syycjly: '所有已成交来源',
		syycjdd: '所有已成交订单',
		cjddzl: '成交订单总量',
		Total: '总计',
		Indivual: '个',
		Common: '共',
		Amount: '金额',
		jyywtj: '金翊业务统计',
		gryjzpm: '个人业绩总排行',
		klywtj: '柯林业务统计',
		zcje: '总成交额',
		ddcjpm: '订单成交排名',
		zwq: '注:M——万 k——千',
		klywbm: '柯林业务部门',
		Collin: '柯林部门',
		MonthlyStatistics: '月统计',
		BusinessRanking: '业务排名',
		Salesman: '业务员',
		TransactionOrderNumber: '成交单数',
		TheMonthlyTurnover: '该月成交额度(RMB)',
		YearMonth: '年月份',
		YearStatistics: '年统计',
		TheAnnualTurnover: '该年成交额度(RMB)',
		Year: '年',
		YearCurrencyStatistics: '年 币种统计',
		grsqbztj: '个人收取币种统计',
		Currency: '币种',
		NumberOfCurrencies: '币种数量',
		UnconvertedAmount: '未换算金额',
		AMOUNT: '成交额',
		VOL: '成交量',
		CompletedOrder: '已成交订单--RMB',
		BusinessReport: '经营报表',
		AccumulatedThisYear: '本年累计',
		AnnualProportion: '年占比',
		MainIncome: '主营收入',
		MainOperatingCost: '主营成本',
		yjyf: '业绩/月份',
		ColinCommission: '柯林分佣',
		GrossProfit: '毛利',
		SalaryCost: '薪酬成本',
		JinyiTax: '金翊税金',
		OtherCosts: '其他成本',
		NetProfit: '净利润',
		ActuallyPaid: '实付',
		Received: '实收',
		Handle: '应付',
		Receivable: '应收',
		Unpaid: '未付',
		Profit: '利润',
		FeeMonthlyTotalPrice: '费用月度总价',
		jyddmx: '集运订单明细',
		jynbddmx: '集运内部订单明细',
		jywbddmx: '集运外部订单明细',
		Box: '箱型',
		jyddhyxx: '集运订单海运箱型',
		ShippingTime: '发货时间',
		To: '至',
		StartDate: '开始日期',
		EndDate: '结束日期',
		hyxxfhsjtj: '海运箱型发货时间统计',
		Volume: '体积',
		Shipping: '海运',
		LandTransport: '陆运',
		AirTransport: '空运',
		IronTransport: '铁运',
		CrossBorderPackage: '跨境包裹',
		ThisYear: '本年',
		Season: '年季',
		Month: '月份',
		Today: '今日',
		PerYear: '每年',
		jyddhytj: '集运订单海运体积',
		jyddlytj: '集运订单陆运体积',
		jyddkytj: '集运订单空运体积',
		jyddkjbgtj: '集运订单跨境包裹体积',
		jyddtytj: '集运订单铁运体积',
		jytjfhsjtj: '集运体积发货时间统计',
		IndexMonth: '指标/ 月份',
		SalesGrossMargin: '销售毛利率',
		szmll: '收支毛利润率',
		ReturnOnCapital: '资金回报率',
		FundStatement: '资金报表(应收)',
		FundStatementPayable: '资金报表(应付)',
		OrderName: '订单名称',
		Uncollected: '未收',
		Forwarder: '货代',
		ConsolidationType: '集运类型',
		szyk: '收支盈亏（实收实付）',
		dcczyk: '导出收支盈亏（实收实付）',
		Income: '收入',
		Expenditure: '支出',
		State: '状态',
		Offeror: '报价人',
		JinyiSales: '金翊销售',
		Client: '客户',
		AccountPeriod: '账期',
		SalesProfitAndLoss: '销售盈亏（应收应付)',
		ExportSalesProfitAndLoss: '导出销售盈亏（应收应付）',
		ExportAllFundsReportPayable: '导出全部资金报表(应付)',
		TotalProfit: '总利润',
		TotalExpenses: '总支出',
		TotalRevenue: '总收入',
		gysfyytj: '供应商费用月份统计',
		YearAndMonthStatistics: '年月份统计',
		BusinessType: '业务类型',
		CustomerName: '客户名',
		zdyhxje: '账单已核销金额',
		qxzywy: '请选择业务员',
		qxzzsqjm: '请选择展示前几名',
		qxzy: '请选择月',
		qnzn: '请选择年',
		qnzgys: '请选择供应商',
	}
	, app: {//金翊app
		appbbgx: 'App版本更新',
		CreateUser: '反馈昵称',
		CreateTime: '反馈时间',
		Remark: '反馈问题',
		Remarks: 'APP更新内容',
		Title: 'APP更新标题',
		Cancel: '取消',
		Save: '保存',
	}
	, userRegister: {//注册
		zcxzh: '注册新账号',
		sjoryxyz: '手机/邮箱验证',
		xxxx: '详细信息',
		wczc: '完成注册',
		Remarks: 'APP更新内容',
		Title: 'APP更新标题',
		Cancel: '取消',
		Save: '保存',
	}
	, online_order: {//在线下单
		consignee: '收货',
		shipper:'发货',
		xzlb:'选择类别',
		zybxxznsxlb:'注意:必须先选择你所需的类别',
		SmartFill:'智能填写',
		addressBook:'地址簿',
		bcddzp:'保存到地址薄',
		copy:'复制',
		orderNumber:'订单号',
		company:'公司',
		FixedTelephone:'固定电话',
		City:'城市',
		Address:'详细地址',
		sender:'发货人',
		Receiver:'收货人',
		phoneNumber:'手机号码',
		emailAddress:'邮箱地址',
		PickupContact:'取货联系人',
		qhlxrsj:'取货联系人手机',
		qhlxrdh:'取货联系人电话',
		cargoInformation:'货物信息',
		GoodTime:'货好时间',
		Consignment:'托寄物',
		termsOfTrade:'贸易条款',
		Box:'箱型',
		totalNumber:'总件数',
		piece:'件',
		ygzzl:'预估总重量',
		GoodsSize:'货物体积',
		Order:'订单',
		ProductName:'品名',
		ddsl:'订单数量',
		ygmz:'预估毛重',
		BookingVolume:'订舱体积',
		ygjs:'预估件数',
		operate:'操作',
		WaybillRemarks:'运单备注',
		qxd:'去下单',
		postode:'邮编',
		dzznq:'地址粘贴区',
		dzznqnr:'粘贴地址信息并按回车键  格式例如：张三 13666666666 上海市闵行区吴泾镇万乐城广场',
		fhxx:'发货信息',
		refresh:'刷新',
		Selectaddress:'选择地址',
		Receiptofgoods:'收货',
		deliveryinformation:'收货信息',
		closure:'关闭',
		Sure:'确定',
		xzjfxx:'新增寄方信息',
		sfswmr:'是否设为默认',
		default:'默认',
		notspecified:'未指定',
		submit:'提交',
		hhrqsj:'货好日期时间',
		date:'日期',
		period:'时间段',
		xzhhsj:'选择货好时间',
		Cancel:'取消',
		ddqr:'订单确认',
		xzsfxx:'新增收方信息',
		ggsybhhw:'贵司已备好货物的时间，跨越小哥上门后可顺利取货',
		shtz:'稍后通知',
		orderid:'订单id',
		quantity:'数量',
		price:'价格',
		totalprice:'总价',
		dctj:'订舱体积',
		ddid:'请输入运单备注',
		Delete: '删除',
		SendingCompany: '寄件公司',
		Sender: '寄件人',
		jjsjhm: '寄件手机号码',
		jjyx: '寄件邮箱',
		jjgddh: '寄件固定电话',
		jjxxdz: '寄件详细地址',
		qhlxr: '取货联系人',
		qhsjhm: '取货手机号码',
		qhgddh: '取货固定电话',
		chgs: '收货公司',
		Consignee: '收货人',
		chsjhm: '收货手机号码',
		chyx: '收货邮箱',
		shgddh: '收货固定电话',
		ReceivingCity: '收货城市',
		shxxdz:'收货详细地址',
		zjs: '总件数',
		CargoVolume: '货物体积',
		Creator: '创建人',
		OrderCategory: '订单类别',
		ShippingTerms: '运输条款',
		remark: '备注',
	},
	AibabaPlatform: {//阿里巴巴平台
		platform_name: '平台名称',
		order_number:'订单编号',
		order_money:'订单金额',
		destination_country:'目的国',
		overdue_time:'逾期时间',
		client_name:'客户名',
		delivery_type:'发货形式',
		brushing_real_orders:'刷单/实单',
		principal:'运营负责人',
		states:'状态',
		salesmen:'业务员',
		order_name:'订单名称',
		pi:'PI号',
		develiver_money:'发货金额',
		moving_time:'搬单时间',
		moving_amount:'搬单金额',
		make_payment:'打款',
		estimated_transaction_fees:'预估服务费',
		payment_time:'打款时间',
		container_number:'集装箱号',
		cz:'操作',
		currency:'币种',
		lr:'录入',
		hbfh:'合并发货',
		qxhb:'取消合并',
		hbfk:'合并付款',
		dlz:'代理证',
		kpzl:'开票资料',
		fp:'发票',
		qxz:'请选择',
		qsrgjzw:'请输入国家中文',
		ddxx:'订单信息',
		fhxx:'发货信息',
		baocun:'保存',
		xiugai:'修改',
		wanjie:'完结',
		jwjtdcc: '将文件拖到此处，或<em>点击上传</em>',
		guanbi: '关闭',
		di:'第',
		tiao:'条',
		fuzhi:'复制',
		shanchu:'删除',
		checkc:'查看',
		delivery_info:'发货信息',
		zxg:'只修改订单',
		searchshow:'查询',
		recover:'恢复',
		platformtype:'抬头',
	}
}
