import config from "@/config"
import http from "@/utils/request"

export default {
	token: {
		url: `${config.API_URL}/Login/login`,
		name: "登录获取TOKEN",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	tokenout: {
		url: `${config.API_URL}/Login/Outlogin`,
		name: "登录获取TOKEN_Out",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	tokenRefresh: {
		url: `${config.API_URL}/Login/tokensession`,
		name: "登录获取TOKEN",
		post: async function (data={}) {
			return await http.post(this.url,data);
		}
	}
}
