<template>
	<div class="scTable" ref="scTableMain" v-loading="loading">
		<div class="scTable-do" v-if="!hideDo">
			<!-- <el-button @click="refresh" icon="el-icon-refresh" circle style="margin-left:15px"></el-button>  :stripe="stripe" style="font-weight: 600;" -->
			<el-popover placement="left" title="列设置" :width="500" trigger="click">
				<template #reference>
					<el-button icon="el-icon-setting" type="primary" circle style="margin-left: 95%"></el-button>
				</template>
				<columnSetting ref="columnSetting" @userChange="columnSettingChange" @save="columnSettingSave" :column="column">
				</columnSetting>

			</el-popover>
		</div>
		<div class="scTable-table">
			<el-table :data="
        tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      " :border="border" :row-key="rowKey" :header-cell-style="{ textAlign: 'center' }"
					  :cell-style="{ textAlign: 'center' }" :key="toggleIndex" ref="scTable" :height="tableHeight"
					  :highlight-current-row="highlightCurrentRow" :show-summary="showSummary" :summary-method="summaryMethod"
					  @selection-change="selectionChange" @current-change="currentChange" @row-dblclick="rowClick"
					  @sort-change="sortChange" @filter-change="filterChange" :row-style="setRowStyle">
				<slot></slot>
				<template v-for="(item, index) in userColumn" :key="index">
					<el-table-column v-if="!item.hide" :column-key="item.prop" :label="item.label" :prop="item.prop"
									 :width="item.width" :sortable="item.sortable" :fixed="item.fixed" :filters="item.filters" :filter-method="
              remoteFilter || !item.filters ? null : filterHandler
            " :show-overflow-tooltip="true">
						<template #default="scope">
							<slot :name="item.prop" v-bind="scope">
								<!-- {{scope.row[item.prop]}}	 -->
								{{ dateFormat(scope.row[item.prop]) }}
							</slot>
						</template>
					</el-table-column>
				</template>
				<el-table-column min-width="1"></el-table-column>
				<template #empty>
					<el-empty :description="emptyText" :image-size="100"></el-empty>
				</template>
			</el-table>
		</div>
		<div class="scTable-page">
			<!--
					  <el-pagination v-if="!hidePagination" background :small="true" :layout="paginationLayout" :total="total" :page-size="pageSize" v-model:currentPage="currentPage" @current-change="paginationChange"></el-pagination>
				   -->
			<!-- <div class="scTable-pagination">
				  <el-pagination
					  @size-change="handleSizeCange"
					  @current-change="handleCurrentChange"
					  :current-page="currentPage"
					  :page-sizes="[10, 50, 100, 200,500,1000]"
					  :page-size="pageSize"
					  layout="total, sizes, prev, pager, next, jumper"
					  :total="tableData.length"
					  >
					  </el-pagination>

					  </div> -->
		</div>
	</div>
</template>

<script>
	import config from "@/config/table";
	import columnSetting from "./columnSetting";

	export default {
		name: "scTable",
		components: {
			columnSetting,
		},
		props: {
			tableName: { type: String, default: "" },
			apiObj: { type: Object, default: () => { } },
			params: { type: Object, default: () => ({}) },
			data: { type: Object, default: () => { } },
			rowKey: { type: String, default: "" },
			column: { type: Object, default: () => { } },
			remoteSort: { type: Boolean, default: false },
			remoteFilter: { type: Boolean, default: false },
			hidePagination: { type: Boolean, default: false },
			hideDo: { type: Boolean, default: false },
			// stripe: { type: Boolean, default: false },
			border: { type: Boolean, default: true },
			highlightCurrentRow: { type: Boolean, default: false },
			showSummary: { type: Boolean, default: false },
			summaryMethod: { type: Function, default: () => { } },
			paginationLayout: {
				type: String,
				default: "total, prev, pager, next, jumper",
			},
		},
		watch: {
			//监听从props里拿到值了
			data() {
				this.tableData = this.data;
				this.total = this.tableData.length;
			},
			apiObj() {
				this.tableParams = this.params;
				this.refresh();
			},
		},
		data() {
			return {
				emptyText: "暂无数据",
				toggleIndex: 0,
				tableData: [],
				pageSize: 5000,
				total: 0,
				currentPage: 1,
				prop: null,
				order: null,
				loading: false,
				tableHeight: "500px",
				tableParams: this.params,
				userColumn: this.column,
			};
		},
		mounted() {
			if (this.apiObj) {
				this.getData();
			} else if (this.data) {
				this.tableData = this.data;
				this.total = this.tableData.length;
			}
			this.$nextTick(() => {
				this.upTableHeight();
			});
			window.addEventListener("resize", this.upTableHeight, true);
		},
		unmounted() {
			window.removeEventListener("resize", this.upTableHeight, true);
		},
		activated() {
			this.$nextTick(() => {
				this.upTableHeight();
			});
			window.addEventListener("resize", this.upTableHeight, true);
		},
		deactivated() {
			window.removeEventListener("resize", this.upTableHeight, true);
		},
		methods: {
			dateFormat(value) {
				//判断一个字符串是否是日期格式
				//var times='2020-12-12 20:05:05';
				//isNaN(itmes) 是排除了times是纯数字的情况，如果不考虑这个情况的话，值判断前面的就可以
				// value.toString().replace(/\s*/g,'');
				if (value == null) return value
				value = value + ''
				// value=value.replace(/\s*/g,''); 正则去掉字符串内的空格
				if (value.length > 24) {
					return value;
				} else {
					if (!isNaN(Date.parse(value)) && isNaN(value)) {
						value = value.substr(0, 10);
					}
					return value;
				}
			},
			handleSizeCange: function (size) {
				this.pageSize = size;
			},
			handleCurrentChange: function (currentPage) {
				this.currentPage = currentPage;
			},
			//更新表格高度
			upTableHeight() {
				const windowHeight = window.innerHeight; // 获取窗口高度  
				const screenHeight = 919; // 获取屏幕高度  
				let tableHeight = 500; // 默认表格高度  
				
				// 计算窗口高度占屏幕高度的百分比  
				const percentage = Math.floor((windowHeight / screenHeight) * 100);  
				
				// 根据百分比调整表格高度  
				// 假设每减少10%的窗口高度，表格高度增加100px  
				if (percentage > 100) {  
					const additionalHeight = windowHeight-screenHeight;
					tableHeight += additionalHeight;  
				}  
  
  				// 设置表格高度  
  				this.tableHeight = `${tableHeight}px`;  
				//this.tableHeight = this.$refs.scTableMain.offsetHeight - 50 + "px";
			},
			//获取数据
			async getData() {
				this.loading = true;
				var reqData = {
					[config.request.page]: this.currentPage,
					[config.request.pageSize]: this.pageSize,
					[config.request.prop]: this.prop,
					[config.request.order]: this.order,
				};
				if (this.hidePagination) {
					delete reqData[config.request.page];
					delete reqData[config.request.pageSize];
				}
				Object.assign(reqData, this.tableParams);

				try {
					var res = await this.apiObj.get(reqData);
				} catch (error) {
					this.loading = false;
					this.emptyText = error.statusText;
					return false;
				}
				var response = config.parseData(res);
				if (response.code != 200) {
					this.loading = false;
					this.emptyText = response.msg;
				} else {
					this.emptyText = "暂无数据";
					if (this.hidePagination) {
						this.tableData = response.data || [];
					} else {
						this.tableData = response.rows || [];
					}
					this.total = response.total || 0;
					this.loading = false;
				}
				this.$refs.scTable.$el.querySelector(
					".el-table__body-wrapper"
				).scrollTop = 0;
			},
			//分页点击
			paginationChange() {
				this.getData();
			},
			//刷新数据
			refresh() {
				this.$refs.scTable.clearSelection();
				this.getData();
			},
			//更新数据 合并上一次params
			upData(params, page = 1) {
				this.currentPage = page;
				this.$refs.scTable.clearSelection();
				Object.assign(this.tableParams, params || {});
				this.getData();
			},
			//重载数据 替换params
			reload(params, page = 1) {
				this.currentPage = page;
				this.tableParams = params || {};
				this.$refs.scTable.clearSelection();
				this.$refs.scTable.clearSort();
				this.$refs.scTable.clearFilter();
				this.getData();
			},
			//自定义变化事件
			columnSettingChange(userColumn) {
				this.userColumn = userColumn;
				this.toggleIndex += 1;
			},
			//自定义列保存
			columnSettingSave(userColumn) {
				config.columnSettingSave(
					this.tableName,
					userColumn,
					this.$refs.columnSetting
				);
			},
			//排序事件
			sortChange(obj) {
				if (!this.remoteSort) {
					return false;
				}
				if (obj.column && obj.prop) {
					this.prop = obj.prop;
					this.order = obj.order;
				} else {
					this.prop = null;
					this.order = null;
				}
				this.getData();
			},
			//本地过滤
			filterHandler(value, row, column) {
				const property = column.property;
				return row[property] === value;
			},
			//过滤事件
			filterChange(filters) {
				if (!this.remoteFilter) {
					return false;
				}
				Object.keys(filters).forEach((key) => {
					filters[key] = filters[key].join(",");
				});
				this.upData(filters);
			},
			//转发原装方法&事件
			selectionChange(selection) {
				this.$emit("selection-change", selection);
			},
			currentChange(selection) {
				this.$emit("current-change", selection);
			},
			rowClick(row, column, event) {
				this.$emit("row-click", row, column, event);
			},
			//改变列表颜色
			setRowStyle(row) {
				let rowBackground = {};
				if (row.row.is_end == 1) {
					// 设置条件
					rowBackground.background = "#dbe3f5";
					return rowBackground;
				}
				// if (row.row.termination == 1) {
				// 	// 设置条件
				// 	rowBackground.background = "#F16076";
				// 	return rowBackground;
				// }
				if (row.row.is_send == "已发送") {
					// 设置条件
					rowBackground.background = "#989898";
					return rowBackground;
				}
				else if (row.row.is_send == "3") {
					// 设置条件
					rowBackground.background = "#dbe3f5";
					return rowBackground;
				}
				if (row.row.cost_states == "已核销") {
					rowBackground.background = "#dbe3f5";
					return rowBackground;
				}

			},
		},
	};
</script>

<style scoped>
  /* Y轴 */
  .el-scrollbar__bar.is-vertical {
    width: 12px;
}
/* X轴 */
.el-table__body-wrapper .is-horizontal {
    height: 12px;
}
	.scTable {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	
	.scTable-table {
		flex: 1;		
	}

	.scTable-page {
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 15px;
	}

	.scTable-do {
		white-space: nowrap;
	}

	element.style {
		height: 532px;
		top: 50px;
	}

</style>

