<!--
 * @Descripttion: 表单表格
 * @version: 1.3
 * @Author: sakuya
 * @Date: 2023年2月9日12:32:26
 * @LastEditors: sakuya
 * @LastEditTime: 2023年2月17日10:41:47
-->

<template>
	<div class="sy-form-table" ref="syFormTable">
		<el-table :data="data" ref="table" border @selection-change="selectionChange" @row-click="rowclickgoods" :row-class-name="rowClassName" 
		 :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }" :height="tableheight">
			<el-table-column type="index" width="50" fixed="left">
				<template #header>
					<el-button v-if="!hideAdd" type="primary" icon="el-icon-plus" size="small" circle @click="rowAdd"></el-button>
				</template>
				<template #default>
				<!-- <template #default="scope"> -->
					<!-- <div :class="['sy-form-table-handle', {'sy-form-table-handle-delete':!hideDelete}]">
						<span>{{scope.$index + 1}}</span>
						<el-button v-if="!hideDelete" type="danger" icon="el-icon-delete" size="small" plain circle @click="rowDel(scope.row, scope.$index)"></el-button>
					</div> -->
					<div class="move" style="cursor: move;"><el-icon-d-caret style="width: 1em; height: 1em;"/></div>
				</template>
			</el-table-column>
			<el-table-column
				fixed="right"
				:label="$t('Consolidation.cz')"
				width="110px"
				>
				<template v-slot="scope">
					<el-link type="success" @click="handlecopy_goods( scope.row)">{{this.$t('Consolidation.fuzhicopy')}}</el-link>
					<el-link type="danger" @click.stop="handleDelete_goods(scope.$index,scope.row)">{{this.$t('Consolidation.sc')}}</el-link>                      
				</template>
			</el-table-column>
			<!-- <el-table-column label="" width="50" v-if="dragSort">
				<template #default>
					<div class="move" style="cursor: move;"><el-icon-d-caret style="width: 1em; height: 1em;"/></div>
				</template>
			</el-table-column> -->
			<slot></slot>
			<template #empty>
				{{placeholder}}
			</template>
		</el-table>
	</div>
</template>

<script>
	import Sortable from 'sortablejs';
	import axios from "axios";
	import config from "@/config";

	export default {
		props: {
			modelValue: { type: Array, default: () => [] },
			addTemplate: { type: Object, default: () => {} },
			placeholder: { type: String, default: "暂无数据" },
			dragSort: { type: Boolean, default: false },
			hideAdd: { type: Boolean, default: false },
			hideDelete: { type: Boolean, default: false },	
			gaibianHeight:{ type: String, default: "1px" },
			height1: { type: [String,Number], default: "auto" },
		},
		data(){
			return {
				data: [],
				clickedRowIndex:0,
				isOK:false,
				tableheight:"auto"
				// tableHeight: this.gaibianHeight*90+"px",// 初始化表格高度
			}
		},
		
		mounted(){
			this.data = this.modelValue
			if(this.dragSort){
				this.rowDrop()
			}
			
		},
		
		 watch: {
			 
			data: {			
			immediate: true, // 立即执行一次
			handler(newVal) {
				this.$nextTick(function() {
					if(this.data.length>4)
					{
						this.tableheight="210px";
					}
					else if(this.data.length<=4)
					{
						this.tableheight="auto";
					}
					let a=this.$route.query.isOKAdd					
					if(a!=null&&a!=undefined){
						if(a=="false"||a==false){
							return a
						}
						else{
							this.$refs.table.setCurrentRow(this.data[this.data.length-1]);
							if(this.clickedRowIndex>0){					
								this.rowclickgoods(this.data[this.clickedRowIndex-1]);	
														
							}else{
								// 触发第一行选中事件
								this.rowclickgoods(this.data[this.clickedRowIndex]);
							}
						}
						
					}else{
						this.$refs.table.setCurrentRow(this.data[this.data.length-1]);
						if(this.clickedRowIndex>0){					
							this.rowclickgoods(this.data[this.clickedRowIndex-1]);										
						}else{
							// 触发第一行选中事件
							this.rowclickgoods(this.data[this.clickedRowIndex]);
						}
					}

				
				});
				this.$emit('update:modelValue', newVal);
			},
			deep: true,
			},
		},
		// watch:{
		// 	modelValue(){
		// 		this.data = this.modelValue
		// 	},
		// 	data:{
		// 		handler(){
		// 			this.$emit('update:modelValue', this.data);
		// 		},
		// 		deep: true
		// 	},
		// },
	
		methods: {
			handlecopy_goods(row)
			{
				this.data.push({
					// product_number:row.product_number,//商品编号
					// product_name:row.product_name,//商品名称
					// specifications_and_models:row.specifications_and_models,//规格型号
					// the_number_of_transactions:row.the_number_of_transactions,//成交数量
					// transaction_unit:row.transaction_unit,//成交单位
					// total_price:row.total_price,//总价
					// currency:row.currency,//币制
					// country_of_origing:row.country_of_origing,//原产国
					// final_destination_country:row.final_destination_country,//最终目的国
					// taxation_and_exemption_methods:row.taxation_and_exemption_methods,//征免
					order_code:row.order_code,
					product_item_number:row.product_item_number,
					product_number:row.product_number,//商品编号
					product_name:row.product_name,//商品名称
					specifications_and_models:row.specifications_and_models,//规格型号
					the_number_of_transactions:row.the_number_of_transactions,//成交数量
					transaction_unit:row.transaction_unit,//成交单位
					total_price:row.total_price,//总价
					total_price1:row.total_price,//总价
					currency:row.currency,//币制
					country_of_origing:row.country_of_origing,//原产国
					final_destination_country:row.final_destination_country,//最终目的国
					taxation_and_exemption_methods:row.taxation_and_exemption_methods,//征免
					quorum:row.quorum,//法定数量
					legal_unit:row.legal_unit,//法定单位
					domestic_source_of_goods:row.domestic_source_of_goods,//境内货源地
					second_quantity:row.second_quantity,//第二数量
					second_unit:row.second_unit,//第二单位
					create_user:row.create_user,
					create_role_id:row.create_role_id,
					user_name:row.user_name,
					modify_user_name: this.$TOOL.data.get("USER_INFO").userName,
					modify_role_id: this.$TOOL.data.get("USER_INFO").role_id,
					modify_user:this.$TOOL.data.get("USER_INFO").real_name,
				});
			},
			handleDelete_goods(index,row){
				if(this.data.length>1)
				{		
					this.data.splice(index, 1)
					if(row.product_item_number==this.data.length+1)
					{
						this.clickedRowIndex =row.product_item_number-1
					}		
					else
					{
						this.clickedRowIndex =row.product_item_number
					}
					if((row.goods_id!=undefined&&row.goods_id!=null)||(row.order_code!=undefined&&row.order_code!=null))
					axios.get(`${config.API_URL}/Customs_Clearance/deleteCustomize`,
					{
						params:{
						c_id:row.goods_id,
						nametype:"货物详情表",
						order_code:row.order_code
						}
					})
					.then((res)=>
					{
						res
					})
					
				}
				else
				{
					this.$message.warning("仅剩一条，无法删除")
				}
			}, 
			//转发原装方法&事件
			selectionChange(selection) {
				this.$emit("selection-change", selection);
			},
			rowclickgoods(row, column, event) {
				this.$emit("row-click", row, column, event);
				if(row==undefined){
					this.clickedRowIndex =0
				}
				else{
					this.clickedRowIndex = row.product_item_number; // 记录点击的行索引或唯一标识	
					
				}
				// setTimeout(() => {
				// 	this.isOK=false
				// }, 200);
			},
			rowClassName({ row }) {
			    return row.product_item_number === this.clickedRowIndex ? 'highlight-row' : ''; // 判断是否应用高亮样式
			},			
			rowDrop(){
				const _this = this
				const tbody = this.$refs.table.$el.querySelector('.el-table__body-wrapper tbody')
				Sortable.create(tbody, {
					handle: ".move",
					animation: 300,
					ghostClass: "ghost",
					onEnd({ newIndex, oldIndex }) {
						_this.data.splice(newIndex, 0, _this.data.splice(oldIndex, 1)[0])
						const newArray = _this.data.slice(0)//
						const tmpHeight = _this.$refs.syFormTable.offsetHeight
						_this.$refs.syFormTable.style.setProperty('height', tmpHeight + 'px')
						_this.data = []
						_this.$nextTick(() => {
							_this.data = newArray
							_this.$nextTick(() => {
								_this.$refs.syFormTable.style.removeProperty('height')
							})

						})
					}
				})
			},
			rowAdd(){
				const temp = JSON.parse(JSON.stringify(this.addTemplate))
				this.data.push(temp)
				this.clickedRowIndex =this.data.length
				// this.upTableHeight()
				
				let a=this.$route.query.isOKAdd					
					if(a!=null&&a!=undefined){
						if(a=="false"||a==false){
							this.$route.query.isOKAdd=true	
						}
					}
					
 // 滚动到表格的最后一行
      this.scrollToBottom();
    },
    scrollToBottom() {
      // 获取 el-table 的 body-wrapper 元素
      const bodyWrapper = this.$refs.table.$el.querySelector('.el-table__body-wrapper');
      
      // 滚动到底部
      if (bodyWrapper) {
        bodyWrapper.scrollTop = bodyWrapper.scrollHeight;
      }
    },
			rowDel(row, index){
				this.data.splice(index, 1)
				// this.upTableHeight()
			},
			//插入行
			pushRow(row){
				const temp = row || JSON.parse(JSON.stringify(this.addTemplate))
				this.data.push(temp)
			},
			//根据index删除
			deleteRow(index){
				this.data.splice(index, 1)
			},
			
		}
	}
</script>

<style scoped>

	.sy-form-table {width: 100%;}	
	.sy-form-table .sy-form-table-handle {text-align: center;}
	.sy-form-table .sy-form-table-handle span {display: inline-block;}
	.sy-form-table .sy-form-table-handle button {display: none;}
	.sy-form-table .hover-row .sy-form-table-handle-delete span {display: none;}
	.sy-form-table .hover-row .sy-form-table-handle-delete button {display: inline-block;}
	.sy-form-table .move {text-align: center;font-size: 14px;margin-top: 3px;}

</style>
