export default {
	login: {//登录
		Suggestion: 'Suggestion: clear the cache before logging in',
		UserName: 'UserName',
		PassWord: 'PassWord',
		LogIn: 'Log in',
		accountLogin: 'Account sign in',
		ClearCache: 'ClearCache',
		qsryhm: 'Please enter your user name/phone number/email address',
		qsrmm: 'Please enter a password',
		CreateNewAccount:'Create a new account',
		noAccount:'No account yet?',
		forgetPassword:'Forget the password?',
	},
	bar: {//导航栏 
		homes: 'Home',
		jyzx: "JinYi Information",
		kzt: "Console",
		grxx: "Personal Information",
		cgshzcx: "Shipping company summary query",
	},
	user: {
		dynamic: 'Dynamic',
		info: 'User Info',
		settings: 'Settings',
		nightmode: 'night mode',
		nightmode_msg: 'Suitable for low light environment,The current night mode is beta',
		language: 'language',
		language_msg: 'Translation in progress,Temporarily translated the text of this view',
		lcsp: 'Process approval',
	},
	userbar: { //标题栏
		CLogistics: 'CLogistics',
		WorkNotice: 'WorkNotice',
		zwxx: 'No new info at the moment',
		Renovate: 'Renovate',
		qbbjyd: 'Mark all as read',
		gyssh: 'Supplier review',
		Supplier: 'Supplier',
		zwxxx: 'No new info at the moment',
		gysxx: 'Supplier Message',
		NewNews: 'New info',
		Unaudited: 'Unaudited',
		Returned: 'Returned',
		yjcn: 'Opinion adoption',
		bbgx: 'Version update',
		grsz: 'Personal settings',
		tcdl: 'Log out',
	},
	Information: {//首页-金翊资讯
		whpj: "Foreign exchange price",
		qzzgyh: "From the data released by the Bank of China, for reference only",
		qbzx: "All information",
		xhmrj: "Current exchange buying price"
	},
	Process: {//流程审批
		fkzy: 'payment summary',
		fkje: 'Payment amount',
		fkfs: 'payment method',
		ddbh: 'OrderNumber:',
		hdmc: 'Shipping agent',
		hdzq: 'Shipping duration',
		jysf: 'Jin Yi charges',
		lczt: 'process status',
		comment: 'Comment',
		cyfs: 'Way',
		print: 'Print',
		cyhw: 'Shipping goods',
		tj: 'Volume',
		qqbt: 'request title',
		dqjd: 'current node',
		dqzt: 'current status',
		lcmb: 'process template',
		appendix: 'appendix',
		title: 'title',
		order_id: 'Process number',
		proposer: 'applicant',
		proposer_time: 'Application date',
		forwarder_name: 'Customer Name',
		trade_clause: 'trade clause',
		pi: 'PI',
		shipping_time: 'Shipment date',
		shipping_name: 'Order Name',
		money_account: 'Payment account',
		scfj: 'Upload attachments',
		bank_payment_voucher: 'bank payment certificate',
		id: 'id',
		cost_name: 'Cost Name',
		payment_amount: 'Invoice amount',
		invoice_number: 'Invoice number',
		currency: 'currency',
		remark: 'remark',
		spjd: 'Approval progress',
		Return: 'Return',
		Pass: 'Pass',
		thly: 'Reason for return',
		Account: 'Account',
		Cancel: 'Cancel',
		Define: 'Define',
		Supplier: 'Supplier',
		Jinyicustomer_name: 'JinyiSALES_name',
		amount_of_lossCNY: 'Estimated amount in RMB',
		amount_of_lossUSD: 'Estimated amount in USD',
		end_state: 'Completion status',
		update_time: 'Update time',
		description_errors: 'Job error description',
		responsibility_distinction: 'Division of responsibilities',
		solution: 'Solution',
		gysshwb: 'Supplier Audit (External)',
		name: 'Personal Name',
		post: 'post',
		enterprise_name: 'Enterprise Name',
		phone: 'phone',
		mailbox: 'email',
		address: 'address',
		yyzz: 'Business license',
		smsjxz: 'Mobile code scanning and downloading (Android only)',
		thyy: 'Reason for return',
		lcmc: 'Process name',
		jxlc: 'New process',
		cxfq: 'Reinitiate',
		xjspjd: 'New approval node',
		
		delete: 'delete',
		all: 'all',
		pending: 'pending',
		csw: 'CC me',
		qxz: 'Please select',
		Check: 'Check',
		Resetting: 'Resetting',
		Revoke: 'Reset',
	},
	freight_rates: {//运价管理-整箱运费查询
		Por: 'POD',
		carrier: 'carrier',
		line: 'line',
		country: 'country',
		freight_rate: 'freight rate',
		transshipment: 'transshipment',
		sailing_Date: 'voyage',
		wharf: 'pier',
		effectity: 'Validity begins',
		validity: 'expiration date',
		remark: 'remark',
		surcharge: 'surcharge',
		weight_limitation: 'weight_limitation',
		other: 'Other',
		dESTINATION_TERMINAL: 'port of destination',
		destination_surcharge: 'destination port surcharge',
		yfgxjl: 'Shipping update record',
		update_user: 'uploader',
		update_time: 'Upload time',
		qsrmdg: 'Please enter the POD',
		zjgx: 'recently updated',
		jlscyy: 'since last time',
		wgx: 'not up-to-date',
		qbscsj: 'delete all data',
		ckgxjl: 'View update history',
		Check: 'search',
		import: 'lead-in',
		Resetting: 'Reset',
		supplier_name: 'Supplier',
	},
	freighCalculation: {//运价管理-国内物流运费计算
		gmwljs: 'Domestic logistics freight calculation',
		destination: 'Destination:',
		qxzmdd: 'Please select a destination',
		volume: 'Volume:',
		qsrtj: 'Please enter the volume (m³)',
		longs: 'Lengths',
		widths: 'Widths',
		heights: 'heights',
		size: 'Size',
		actualWeight: 'ActualWeight:',
		sqssjzl: 'Please enter the actual weight',
		billingWeights: 'Chargeable Weight:',
		calculate: 'Calculate',
		Startinglocation: 'Original location:',
		shanghai: 'shanghai',
		aging: 'ShippingTime:',
		minCharge: 'BasicFreight:',
		finalCost: 'FinalFreight:',
		day: 'day'
	},
	quotation: {//报价管理
		bjxzr: 'Select bidder',
		CreationTime: 'CreationTime',
		InitiateQuotation: 'InitiateQuotation',
		id: 'id',
		externalQuotation_id: 'externalQuotation_id',
		state: 'state',
		confirms: 'status',
		customer: 'Customer',
		phone: 'Phone',
		email: 'Email',
		pol: 'Pol',
		pod: 'Pod',
		trade_clauseId: 'TradeClause',
		carrier: 'Carrier',
		box_box: 'BoxBox',
		weight: 'Box-box required',
		volume: 'Gross Weight',
		voyage: 'Voyage',
		exchangeRate: 'ExchangeRate',
		etd: 'Etd',
		create_user: 'create_user',
		create_time: 'create_time',
		update_user: 'update_user',
		Remake: 'Remake',
		applicationTime: 'applicationTime',
		Applicant: 'Applicant',
		name: 'ApplicantName:',
		department: 'ApplicantDepartment:',
		applicantPhone: 'ApplicantPhone:',
		applicantEmail: 'ApplicantEmail:',
		address: 'Destination address',
		y_remarks: 'Sales Remarks',
		c_remarks: 'Shipping remarks',
		consignment_type: 'Consignment type',
		goback: 'Order Status',
		bjid: 'quote number',
		BidderTime: 'Quote Time',
		quote_information: 'quote information',
		Bidder: 'quoter',
		BidderPhone: 'quoter Phone',
		BidderEmail: 'quoter Email',
		to_customer: 'Customer Name:',
		number: 'number of packages',
		transport: 'mode of transport',
		actual_value_goods: 'actual Value of Goods',
		taxes: 'taxes',
		Operation: 'Operation',
		AddRows: 'Add rows',
		xfbgkybjdwbtx: 'All editable items in the form below are required',
		qxz: 'Please select',
		ShipperInformation: 'ShipperInformation',
		RecipientInformation: 'Consignee Information',
		fhrxxhshrxx: '(Consignor information = notifier, consignee information = consignee, required when transferring to a consolidation order, and can be manually modified)',
		PriceC: 'Price(CNY)',
		PriceU: 'Price(USD)',
		LCL: 'LCL',
		FCL: 'FCL',
		startTime: 'startTime',
		endTime: 'endTime',
		to: 'To',
		all: 'all',
		qsrgkmc: 'Please enter the port of destination',
		xzrq: 'select date',
		jyqf: 'Consolidation division',
		qxzysfs: 'Please select a shipping method',

		Quoted:'Quoted',
		returned:'returned',
		NotQuoted:'Not quoted',
		khyqr:'Customer confirmed',
		yxd:'Order has been placed',
		wxd:'No order placed',
		productName:'product name',
		yjfhsj:'Estimated delivery time',
		boxType:'box type',
		zmz:'total gross weight',
		totalVolume:'total volume',
		ystk:'Shipping Terms',
		fhdz:'shipping address',
		chrdz:'Consignee address',
		zzjyddlx:'Go to consolidation order type',
		Remarks:'Remarks',

		Save: 'Save',
		Delete: 'Delete',
		CopySave: 'Copy and save',
		Update: 'Update',
		Obsolete: 'Obsolete',
		Copy: 'Copy',
		Check: 'search',
		Resetting: 'Reset',
		Develop: 'unfold',
		ReturnToModify: 'ReturnToModify',
		excelTableExport: 'excelTableExport',
		Return: 'Return',
		Quote: 'Quote',
		Cancel: 'Cancel',
		ConsolidationOrder: 'transfer to ConsolidationOrder',

	},
	Port_of_Arrival: {//目的港设置
		id: 'id',
		cnName: 'PortChinese',
		enName: 'portEnglish',
		portCode: 'portCode',
		country: 'Country',
		enCountry: 'National English',
		sevenContinentsName: 'Home route',
		drmdg: 'port of destination',

		Develop: 'unfold',
		Check: 'search',
		Resetting: 'Reset',
	},
	progress_sc: {//出运安排
		id: 'id',
		pi: 'PI',
		order_name: 'orderName',
		freight_shipping_way: 'shipping mode',
		freight_shipping_status: 'ShippingStatus',
		freight_count: 'NumberOf packages',
		freight_net_weight: 'NetWeight(KG)',
		freight_gross_weight: 'CargoGrossWeight(KG)',
		freight_ton: 'CargoVolume(M³)',
		freight_size: 'CargoSize(CM)',
		freight_ok_date: 'deliveryDate',
		storage_remark: 'remark',
		create_user: 'createUser',
		create_time: 'createTime',
		salesman: 'Sales',
		attachment_tag: 'AttachmentUploadStatus',
		pictrue: 'Pictrue',

		Develop: 'Unfold',
		Check: 'search',
		Resetting: 'Reset',
	},
	ShippingDisplay: {//出运安排-出运显示
		id: 'id',
		orderName: 'orderName',
		Business: 'Sales',
		pi: 'PI',
		issend: 'send status',
		Dimensions: 'Dimensions（manufacturing/inside diameter/outside diameter）',
		quantity: 'Quantity',
		boxType: 'BoxType',
		color: 'Color',
		surfaceTreatment: 'SurfaceTreatment',
		CorrugatedDirection: 'CorrugatedDirection',
		AdhesivePosition: 'AdhesivePosition',
		packagingRequirements: 'packagingRequirements',
		deliveryDate: 'DeliveryDate',
		freightForwarder: 'FreightForwarder',
		ReceiptStatus: 'ReceiptStatus',
		contactPerson: 'CcontactPerson',
		orderRequirements: 'OrderRequirements',

		sDate: 'Date',
		SCheckDate: 'CheckDate',
		SLastEditDate: 'LastEditDate',
		SBillerNo: 'BillerNo	',
		SBillerName: 'BillerName',
		SChkNo: 'ReviewerCode',
		SChkName: 'ReviewerName',
		SDeptNo: 'DepartmentCode',
		SDeptName: 'DepartmentName',
		SEmpNo: 'Sales Code',
		SEmpName: 'Sales Name',
		SCustNo: 'CustomerCode',
		SCustName: 'Customer Name',
		SCurrNo: 'CurrencyCode',
		SCurrName: 'CurrencyName',
		SCurrRate: 'ExchangeRate',
		C_13823755841: 'EstimatedPalletSize',
		C_24078915534: 'Confirm',
		C_639328797626: 'NewAndOldCustomers',
		Remake: 'Remake',
		sEntryID: 'LineNumber',
		is_produce: 'Whether production has been delivered',
		sItemNo: 'MaterialCode',
		sItemName: 'MaterialName',
		sItemModel: 'Specification',
		sPropNo: 'PropertyEncoding',
		sPropName: 'PropertyName',
		sBatchNo: 'BatchNumber',
		sSerialNo: 'SerialNumber',
		sUnitNo: 'MeasurementUnitCode',
		sUnit: 'MeasurementUnit',
		sPrice0: 'UnitPriceBeforeDiscount',
		sAmt0: 'AmountBeforeDiscount',
		sDiscRate: 'DiscountRate',
		sDiscAmt: 'DiscountAmount',
		sPrice: 'ActualUnitPrice',
		sAmt: 'ActualAmount',
		sTaxRate: 'TaxRate',
		sTaxPrice: 'ActualUnitPriceIncludingTax',
		sTax: 'TaxAmount',
		sAllAmt: 'TotalPriceAndTax',
		sUnit2No: 'AuxiliaryUnitOfMeasurementCode',
		sUnit2: 'AuxiliaryUnitOfMeasurement',
		SUnitRate: 'ConversionRate',
		sQty: 'quantity',
		sQty2: 'AuxiliaryUnitOfMeasurementData',
		sEntryNote: 'LineNote',
		c_14961262566: 'ShippingRemarks',
		jyqf: 'consolidation distinction',
		qxzysfs: 'Please select a shipping method',
		xzczr: 'Select operator',
		fsdd: 'send order',

		Save: 'Save',
		Cancel: 'Cancel',
		Develop: 'Develop',
		Check: 'search',
		Resetting: 'Reset',
	},
	Consolidation: {//集运订单
		id: 'id',
		PINumber: 'PINumber',
		CustomerName: 'Customer Name',
		OrderName: 'OrderName',
		CreationTime: 'CreationTime',
		OrderNumber: 'OrderNumber',
		Requester: 'EntrustedUnit',
		BookingAgent: 'BookingAgent',
		Bidder: 'Qoter',
		DeliveryTime: 'Delivery Time',
		TermsOfTrade: 'TradeTerms',
		PolicyNumber: 'Policy Number',
		destination_port: 'destination_port',
		consignee: 'consignee',
		Inquire: 'search',
		Add: 'Add',
		MoreOperations: 'MoreOperations',
		CopyOrder: 'CopyOrder',
		ExportLedger: 'ExportLedger',
		ExportAllLedgers: 'ExportAllLedgers',
		End: 'End',
		CancelEnd: 'CancelEnd',
		Label: 'Label',
		Locking: 'Lock',
		Unlock: 'Unlock',
		Delete: 'Delete',
		Reset: 'Reset',
		Expand: 'unfold',
		DeclarationTitle: 'CustomsDeclarationHeader',
		CustomsClearance: 'CustomsDeclaration',
		FactoryCar: 'FactoryCar',
		QuantityOfOrder: 'OrderQuantity',
		BookingRemarks: 'BookingRemarks',
		MasterOrderNumber: 'MBL',
		ShipName: 'VESSEL',
		BoxVolume: 'BoxVolume',
		ShippingCompany: 'ShippingCompany',
		Por: 'Por',
		Pod: 'Pod',
		CutOffTime: 'CutoffTime',
		ClosingTime: 'ClosingTime',
		ExchangeTime: 'ExchangeTime',
		BusinessPeople: 'Sales',
		ConsignmentType: 'shipping mode',
		ServiceType: 'ServiceType',
		BargeETD: 'BargeETD',
		ForeignAgent: 'ForeignAgent',
		ActualTotalVolume: 'ActualTotalVolume',
		ActualGrossWeight: 'ActualGrossWeight',
		ActualTotalNumberOfPieces: 'TheActualTotalNumberOfPieces',
		Value: 'Value',
		PaymentMethod: 'PaymentMethod',
		BargeName: 'BargeName',
		BargeVoyage: 'BargeVoyage',
		SONumber: 'SONumber',
		CaseNumber: 'container Number',
		Founder: 'Creator',
		Pier: 'Pier',
		ReceivableWriteOffTime: 'ReceivableWrite-OffTime',
		ReceivableWriteOffStatus: 'ReceivableWrite-OffStatus',
		ChineseName: 'ChineseProductName',
		PlaceOfDelivery: 'PlaceOfDelivery',
		Destination: 'Destination',
		Route: 'Route',
		BillOfLadingForm: 'BL Form',
		NetWeight: 'NetWeight',
		PaymentAddress: 'PaymentAddress',
		AdditionalConditions: 'AdditionalConditions',
		DateOfIssue: 'IssueDate',
		IssueAddress: 'IssuingAddress',
		OverseasShippingAddress: 'OverseasShippingAddress',
		PortOfDischarge: 'PortOfDischarge',
		ShippingAgent: 'ShippingAgent',
		Premium: 'Premium',
		TransitPortCode: 'TransitPortCode',
		StateName: 'StateName',
		StatusExit: 'StatusExit',
		StatusComplete: 'StatusComplete',
		StateTime: 'StateTime',
		StateImportAndExport: 'StateImportAndExport',
		Voyage: 'Voyage',
		CargoMarking: 'SOC Mark',
		BargePort: 'BargePort',
		Sender: 'shipper',
		Receiver: 'consignee',
		order_type: 'Order type',
		Mark: 'Mark',
		EnglishProductName: 'EnglishProductName',
		TermsOfCarriage: 'TermsOfCarriage',
		EstimatedShippingTime: 'EstimatedShippingTime',
		FirstNotifier: 'FirstNotifier',
		SecondNotifier: 'SecondNotifier',
		ActualNumber: 'ActualNumber',
		BySea: 'ocean shipping',
		BoxGoods: 'BoxGoods',
		WhereIsTheBoat: 'WhereIsTheBoat',
		Number: 'Number',
		Weight: 'Gross Weight',
		Volume: 'Volume',
		AlertOthers: 'Notifier',
		BillOfLadingRemarks: 'BillOfLadingRemarks',
		RemarksForMultipleProductNames: 'RemarksForMultipleProductNames',
		CabinRemarks: 'CabinRemarks',
		OperationNotes: 'OperationNotes',
		EventName: 'EventName',
		BusinessInformation: 'BusinessInformation',
		DistributionInformation: 'DistributionInformation',
		BillOfLadingInformation: 'BillOfLadingInformation',
		InsideInformation: 'InsideInformation',
		OrderStatus: '< OrderStatus',
		goback: 'Order Status',
		Save: 'Save',
		sfcd: 'Whether to forward',
		ExportDocuments: 'ExportDocuments',
		CargoManagement: 'CargoManagement',
		DeliveryPicture: 'DeliveryPicture',
		AbnormalSituation: 'AbnormalSituation',
		CustomsInformation: 'CustomsInformation',
		CustomsDeclaration: 'CustomsDeclaration',
		ReleaseSheet: 'ReleaseSheet',
		Download: 'Download',
		CustomsClearanceInformation: 'CustomsClearanceInformation',
		Telephone: 'Telephone',
		UploadData: 'UploadData',
		ExpenseEntry: 'ExpenseEntry',
		ToAir: 'TRANSFER ToAir',
		ToGround: 'TRANSFER ToGround',
		ToRail: 'TRANSFER ToRail',
		ToUSPS: 'TRANSFER ToUSPS',
		AddBoxCapacity: 'AddBoxCapacity',
		BillOfLadingNumber: 'BL Number',
		Airline: 'Airline',
		FlightNumber: 'FlightNumber',
		VolumeToWeightRatio: 'VolumeToWeightRatio',
		VolumeWeight: 'VolumeWeight',
		SubBubbleWeight: 'SubBubbleWeight',
		BillableWeight: 'BillableWeight',
		BubbleWeight: 'BubbleWeight',
		ShippingStation: 'ShippingStation',
		UnloadingStation: 'UnloadingStation',
		RoundTrip: 'RoundTrip',
		PortOfEntryAndExit: 'PortOfEntryAndExit',
		TrainNumber: 'TrainNumber',
		BrainDate: 'BrainDate',
		QuoteNumber: 'QuoteNumber',
		SpecialRequest: 'SpecialRequest',
		OrderTime: 'OrderTime',
		SingleNumber: 'TRACING Number',
		PickupAddress: 'PickupAddress',
		PickupTime: 'PickupTime',
		ShippingAddress: 'ShippingAddress',
		TransportationRemarks: 'TransportationRemarks',
		OrderRemarks: 'OrderRemarks',
		ExpressTrackingNumber: 'Express Tracking Number',
		Boxing: 'Boxing',
		Tray: 'Tray',
		EstimatedTax: 'EstimatedTax',
		ActualTax: 'ActualTax',
		ShippingWarehousing: 'Shipping/Warehousing',
		StartDate: 'StartDate',
		EndDate: 'EndDate',
		To: 'To',
		qxz: 'Please choose',
		qxzfkfs: 'Please select a payment method',
		qxzrq: 'Select date',
		qxzwtdw: 'Please select an entrusting unit',
		qxzdcdl: 'Please select a booking agent',
		qxzbjr: 'Please select the bidder',
		qxzhkgs: 'Please select the airline',
		qxzhzxbj: 'Please select the Cargo marking',
		qxzhx: 'Please select the route',
		qxztdxs: 'Please select the form of bill of lading',
		qxzsjcfsj: 'Select actual departure time',
		qxzmytk: 'Please select trade terms',
		FileName: 'file name',
		FileType: 'File Type',
		Cancel: 'Cancel',
		Export: 'Export',
		jwjtdcc: 'Drag the file here, or click to upload',
		qgzlqr: 'Customs clearance data confirmation',
		qxzbgtt: 'Please select the title of customs declaration',
		qxzcgs: 'Please select a shipping mode',
		LCL: 'LCL',
		Supplier: 'Supplier',
		Default: 'Default',
		AddALabel: 'Add a Label',
		LabelType: 'LabelType',
		TagName: 'TagName',
		xzbgd: 'Download customs declaration',
		xzyc: 'ADD abnormity',
		dfjcflb: 'Storage list of electronic release files',
		xzzl: 'Download data',
		qxzxxxx: 'Please select box type box volume',
		Box: 'box type',
		ContainerVolume: 'Box volume',
		BoxNumber: 'CONTAINER NO / SEAL NUMBER',
		AddBoxType: '+Add box type',
		ExceptionFlag: 'ExceptionFlag',
		SplitBubbleRatio: 'SplitBubbleRatio',
		djxzxflb: 'Click the list below to download',
		BasicInformation: 'BasicInformation',
		UploadWaterBill: 'UploadWaterBill',
		UploadInvoice: 'UploadInvoice',
		ExportBill: 'ExportBill',
		PaymentRequest: 'PaymentRequest',
		BusinessType: 'BusinessType',
		BillAttribute: 'BillAttribute',
		USDollarCurrency: 'U.S.DollarCurrency',
		Attributes: 'Attributes',
		SettlementUnit: 'SettlementUnit',
		FeeName: 'COSTName',
		Currency: 'Currency',
		BillAmount: 'BillAmount',
		InvoiceNumber: 'InvoiceNumber',
		xjjt: 'Enquiry',
		xjjtfile: 'Enquiry file',
		bjjt: 'Offer',
		bjjtfile: 'Offer file',
		FeeStatus: 'FeeStatus',
		EntryPerson: 'EntryPerson',
		LastModifiedBy: 'LastModifiedBy',
		LastModified: 'LastModified',
		Operate: 'Operate',
		CurrentExchangeRate: 'CurrentExchangeRate',
		Receivable: 'Receivable',
		Handle: 'PAYABLE',
		Ticket: 'Ticket',
		Total: 'Total',
		TotalGrossProfit: 'TotalGrossProfit',
		RMBEquivalent: 'RMBEquivalent',
		Profit: 'Profit',
		Quantity: 'Quantity',
		UnitPrice: 'UnitPrice',
		Update: 'MODIFY',
		Bill: 'Bill',
		Submit: 'Submit',
		payment_amount: 'InvoiceAmount',
		Remark: 'Remark',
		Title: 'Title',
		order_id: 'Process number',
		proposer: 'Proposer',
		proposer_time: 'ApplicationDate',
		forwarder_name: 'CustomerName',
		shipping_time: 'ShipmentTime',
		money_account: 'PaymentAccount',
		scfj: 'Upload attachments',
		ApplicationDate: 'ApplicationDate',
		qxzzdsx: 'Please select a billing attribute',
		khddzl: 'Customer order information',
		khzl: 'Customer information',
		contract: 'Contract',
		schschqdjsfbq: 'Please click the upper TAB again after uploading successfully or deleting',
		contractfile: 'Contract file',
		customerinformation: 'Customer information',
		customerinformationfile: 'Customer information file',
		psd: 'Review form',
		psdfile: 'Review form file',
		producttp: 'Product picture',
		producttpfile: 'Product picture file',
		jmt: 'Piece fur body',
		jmtfile: 'Piece fur body file',

		//报关 拖车
		electronic_commission:'electronic commission',
		qztjfjcscwts:'Please upload the power of attorney near Add',
		exit_customs:'Exit customs',
		customs_at_the_place_of_declaration:'Customs at the place of declaration',
		port_of_departure:'port of departure',
		domestic_shipper:'Domestic shipper',
		social_credit_code:'social credit code',
		customs_code:'Customs code',
		overseas_consignee:'Overseas consignee',
		aeo_customs_code:'AEO customs code',
		production_and_sales_unit:'Production and sales unit',
		transportation_method:'Transportation method',
		means_of_transport:'means of transportation',
		voyage:'Voyage',
		waybill_number:'Waybill number',
		ship_name_voyage:'Ship name voyage',
		supervision_method:'Supervision method',
		nature_of_tax_exemption:'Nature of tax exemption',
		permit_number:'Permit number',
		contract_agreement_number:'contract agreement number',
		trading_country:'trading country',
		shipping_port:'Shipping port',
		arrive:'Ship to country',
		transaction_method:'Transaction method',
		freight:'freight',
		shipping_amount:'Amount',
		shipping_currency:'Currency',
		premium:'Premium',
		miscellaneous_expenses:'Miscellaneous expenses',
		packaging_type:'Packaging type',
		number_of_pieces:'Number of pieces',
		gross_weight:'gross weight',
		net_weight:'net weight',
		mark_code:'mark code',
		special_relationship:'special relationship',
		affect_price:'Affect price',
		royalties:'royalties',
		order_code:'order number',
		product_item_number:'Item number',
		product_number:'Product Number',
		product_name:'product name',
		specifications_and_models:'Specifications and models',
		the_number_of_transactions:'The number of transactions',
		transaction_unit:'Transaction unit',
		total_price:'total price',
		currency:'Currency',
		quorum:'quorum',
		legal_unit:'legal unit',
		domestic_source_of_goods:'Domestic source of goods',
		country_of_origing:'country of origin',
		second_quantity:'second quantity',
		second_unit:'second unit',
		final_destination_country:'final destination country',
		taxation_and_exemption_methods:'Taxation and exemption methods',
		container_name:'Container Number',
		specification:'Specification',
		lcl_remark:'LCL',
		allproduct_item_number:'Product management item number',
		accompanying_documents:'accompanying documents',
		document_number:'Document number',
		bcddfs:'Save to be sent',
		bcdcgx:'Save to drafts',
		bgdlr:'Customs declaration entry',
		chxx:'Shipment information',
		hwxq:'Cargo details',
		jzxxx:'Container information',
		sfdzxx:'Accompanying documentary information',
		chaxun:'Inquire',
		suoqi:'shrink',
		zhankai:'Expand',
		luru:'Send in bulk',
		plfs:'批量发送',
		baocun:'save',
		ddxx:'order information',
		bill_of_lading_number:'Bill of lading number',
		shipping_company:'shipping company',
		port_of_loading:'port of loading',
		ship_name:'ship name',
		port_of_discharge:'port of discharge',
		contact_person:'Contact person',
		contact_phone:'contact number',
		box_box:'Box volume',
		destination:'Destination',
		container_loading_address:'Container loading address',
		loading_time:'Loading time',
		xgbc:'Modify and save',
		box_num:'Container volume',
		box_type:'Box',
		queren:'confirm',
		quxiao:'Cancel',
		scyp:'Upload provisioning',
		cz:'operate',
		sc:'delete',
		fuzhicopy:'copy',
		chakan:'Check',
		bgd:'customs declaration',
		fxd:'release order',
		bgwtxy:'Customs declaration entrustment agreement',
		schdd:'Generate checklist',		
		tjfj:'Add attachments',		
		xzqbfj:'Download all attachments',		
		qingxuanze:'please choose ',		
		fuzhifahuoren:'copy shipper',		
		qsr:'please enter ',		
		zwsj:'No data',
		djzjbz:'unit price/total price/currency',
		heji:'total',
		cjsl:'the number of transactions',
		fdsl:'quorum',
		desl:'second quantity',
		zonjia:'total price',
		swsdspbh:'ten-digit product number',
		qb:'all',
		gdxx:'More information',
		kuajingFahuofangshi:'Shipping method',
	},
	cost: {//流程审批
		Revoke: 'Cancel write-off',
		ddbh: 'order number',
		title: 'title',
		proposer: 'proposer',
		proposer_time: 'Application date',
		forwarder_name: 'Customer Name',
		pi: 'PINumber',
		shipping_name: 'Order Name',
		id: 'id',
		cost_name: 'COST Name',
		invoice_number: 'Invoice number',
		currency: 'currency',
		remark: 'remark',
		Return: 'Return',
		Pass: 'Pass',
		thly: 'Reason for return',
		Cancel: 'Reason',
		Define: 'confirm',
		Supplier: 'Supplier',
		Jinyicustomer_name: 'Jinyisales_name',
		amount_of_lossCNY: 'Estimated amount in RMB',
		amount_of_lossUSD: 'Estimated amount in USD',
		end_state: 'Completion status',
		update_time: 'Update time',
		description_errors: 'Job error description',
		responsibility_distinction: 'Division of responsibilities',
		solution: 'Solution',
		post: 'post',
		qxz: 'Please choose',
		Check: 'Check',
		Resetting: 'Resetting',
		WriteOffAmount: 'Write-OffAmount',
		ExchangeRate: 'ExchangeRate',
		Income: 'Income',
		Expenditure: 'Expenditure',
		Profit: 'Profit',
		RMBPayable: 'RMBPayable',
		HandlingFee: 'HandlingFee',
		WriteOffTime: 'Write-OffTime',
		WriteOffMethod: 'Write-OffMethod',
		CancelAccount: 'CancelAccount',
		WriteOffRemarks: 'Write-OffRemarks',
		CostAttribute: 'CostAttribute',
		FeeStatus: 'FeeStatus',
		ReconciliationStatus: 'Reconciliation Status',
		ReconciliationTime: 'ReconciliationTime',
		SettlementUnit: 'SettlementUnit',
		ConsolidationType: 'ConsolidationType',
		Bidder: 'Bidder',
		Currency: 'Currency',
		DeliveryTime: 'DeliveryTime',
		BusinessType: 'BusinessType',
		CreationTime: 'CreationTime',
		YearMonth: 'YearMonth',
		VAT: 'VAT',
		AdditionalTax: 'AdditionalTax',
		CorporateIncomeTax: 'CorporateIncomeTax',
		StampDuty: 'StampDuty',
		SalesFee: 'SalesFee(Platform)',
		SalesFeeAdvertising: 'SalesFee(Advertising)',
		AdministrativeExpensesFits: 'AdministrativeExpenses(EmployeeBenefits)',
		AdministrativeExpenses: 'AdministrativeExpenses(OfficeExpenses)',
		Total: 'Total',
		Time: 'Time',
		Years: 'Years',
		Add: 'Add',
		ImportOtherCosts: 'ImportOtherCosts',
		ExportOtherCosts: 'ExportOtherCosts',
		ImportJinyiTax: 'ImportJinyiTax',
		ExportJinyiTax: 'ExportJinyiTax',
		ExportBill: 'ExportBill',
		ExportAllBills: 'ExportAllBills',
		BatchWriteOff: 'BatchWrite-Off',
		BatchStarReconciliation: 'BatchStarReconciliation',
		Delete: 'Delete',
		ProcessSubmission: 'ProcessSubmission',
		Update: 'Update',
		BillAmount: 'BillAmount',
		Clerk: 'Clerk',
		PaidInRatio: 'PaidInRatio',
		Attributes: 'Attributes',
		ApplicantDate: 'ApplicantDate',
		WriteoffCurrency: 'WriteoffCurrency',
		State: 'State',
		Save: 'Save',
		qxzfylx: 'Please select the cost attribute',
		qxzywlx: 'Please select a business type',
		qxzjsdw: 'Please select the settlement unit',
		qxzbz: 'Please select currency',
		qxzhxfs: 'PleaseSelectTheVerificationMethod',
		StartDate: 'StartDate',
		EndDate: 'EndDate',
		To: 'To',
		yxwxthlgssj: 'Note: The following is estimated data based on the system exchange rate, for reference only',
		plhxsmjhrmbyfkhx: 'Reminder: US dollars and RMB should be written off separately when writing off in batches',
		WrittenOff: 'WrittenOff',
		NotWrittenOff: 'NotWrittenOff',
		Strip: 'Strip',
		Sum: 'Sum',
		AccountNumber: 'AccountNumber',
		FinancialDocumentNumber: 'FinancialDocumentNumber',
		BillNotes: 'BillNotes',
		Receivable: 'Receivable',
		Invoice: 'Invoice',
		TotalAmount: 'TotalAmount',
		AmountNotWrittenOff: 'AmountNotWrittenOff',
		TheAmount: 'TheAmount',
		TaxRate: 'TaxRate(%)',
		ExpensesNotWrittenOff: 'ExpensesNotWrittenOff',
		Requester: 'Requester.',
		Tax: 'Tax',
		PriceWithoutTax: 'PriceWithoutTax',
		TotalPriceInLocalCurrency: 'TotalPriceInLocalCurrency',
		BillNumber: 'BillNumber',
		WriteOff: 'WriteOff',
		ViewWaterBill: 'ViewWaterBill',
		ViewInvoice: 'ViewInvoice',
		Reconciliation: 'Reconciliation',
		BasicInformation: 'BasicInformation',
		ReceivableDetails: 'ReceivableDetails',
		WriteOffRecords: 'WriteOffRecords',
		Handle: 'PAYABLE',
		PayableDetails: 'PayableDetails',
		Name: 'Name',
		Month: 'Month',
		GrossProfit: 'GrossProfit',
		ColinCommission: 'FORESTCommission',
		Commission: 'Commission',
		CommissionRemarks: 'CommissionRemarks',
		Export: 'Export',
		ImportSalary: 'ImportSalary',
		ExportSalary: 'ExportSalary',
		Department: 'Department',
		EmployeeSalary: 'EmployeeSalary',
		TotalWages: 'TotalWages',
		ImportFiveInsurancesAndOneHousingFund: 'ImportFiveInsurancesAndOneHousingFund',
		ExportFiveInsurancesAndOneHousingFund: 'ExportFiveInsurancesAndOneHousingFund',
		BasicEndowmentInsuranceForUrbanWorkers: 'BasicEndowmentInsuranceForUrbanWorkers',
		BasicMedicalInsuranceForEmployees: 'BasicMedicalInsuranceForEmployees',
		UnemploymentInsurance: 'UnemploymentInsurance',
		InjuryInsurance: 'InjuryInsurance',
		LocalAdditionalMedicalInsurance: 'LocalAdditionalMedicalInsurance',
		ProvidentFund: 'ProvidentFund',
		TotalGrossProfit: 'TotalGrossProfit ',
		TotalRebate: 'TotalRebate ',
		TotalCommission: 'TotalCommission',
		TotalProvidentFund: 'TotalProvidentFund',
		TotalSocialSecurity: 'TotalSocialSecurity',
		StartMonth: 'Start month',
		endMonth: 'End month',
		qxzgw: 'Please select a position',
		qxzwtdw: 'Please select the entrusting unit',
	},
	customerManage: {//客户管理
		Username: 'Username',
		CustomerAddress: 'Customer Address',
		Nation: 'Nation',
		BossName: 'Boss name',
		Relationway: 'Relation way',
		CompanyRemark: 'Company remark',
		PurchaseIntention: 'Purchase intention',
		PurchaseAmount: 'Purchase amount',
		TransactionCurrency: 'Transaction currency',
		MeansofPayments: 'Means of payments',
		CustomerGrade: 'Customer grade',
		CustomerStage: 'Customer stage',
		SocialPlatformAccount: 'Social platform account',
		CustomerSource: 'Customer source',
		PhoneNumber: 'Mobile phone number',
		Mail: 'E-mail',
		Nature: 'nature',
		BelongsToTheBusiness: 'belongs to the business',
		SocialUnifiedCreditCode: 'Social Unified Credit Code',
		AccountPeriod: 'account period',
		SettlementMethod: 'Settlement Method',
		ReconciliationMethod: 'Reconciliation method',
		TypeOfEnterprise: 'Enterprise Type',
		Add: 'Add',
		Edit: 'Edit',
		BusinessLicense: 'Business License',
		CompanyName: 'Company Name',
		Address: 'Detailedaddress',
		CustomerName: 'Customer Name',
		CustomerPhone: 'Customer Phone',
		CustomerEmail: 'Customer Email',
		title1:'Follow-up date setting',
		currenttime:'Initial date',
		settingtraget:'Set goals',
		targettime:'Target date',
		enableorno:'Enable or not',
		TypeOfCompany: 'Company Type',
		FileLicenseInformation: 'File License Information',
		UploadBusinessLicense: 'Upload business license',
		CustomerBusinessLicense: 'Customer Business License',
		FileName: 'File Name',
		FileType: 'File Type',
		Category: 'Category',
		CreationTime: 'Creation time',
		Founder: 'Creator',
		Operate: 'Operation',
		Update: 'Update',
		NewEmail: 'NewEmail',
		NewPhone: 'NewPhone',
		cxwbtx: 'This is required',
		ViewImage: 'View Image',
		follow_up_dynamics:'Follow-up dynamics',
		djckdt:'Click to view the dynamic',
		tjdt: 'Add dynamic',
		jcxx: 'Basic information',
		tzxx: 'Feature information',
		lxrxx: 'Contact information',
	},
	supplier: {//供应商管理
		TotalProvidentFund: 'Total Provident Fund',
		Add: 'Add new',
		Status: 'Status',
		Addtag: 'Add tag',
		BatchOperation: 'Batch Operation',
		Blacklist: 'Blacklist',
		ToDelete: 'to delete',
		ToRecycleBin: 'to the recycle bin',
		RecycleBin: 'RecycleBin',
		Audit: 'Audit',
		WasReturnedToTheSupplier: 'was returned to the supplier',
		CommonTags: 'Common tags',
		Expand: 'Expand',
		CompanyLetterhead: 'Company Letterhead',
		ReconciliationMethod: 'Reconciliation method',
		SettlementMethod: 'Settlement Method',
		Nature: 'nature',
		Nation: 'Nation',
		AccountPeriod: 'account period',
		CreationTime: 'Creation time',
		Founder: 'Creator',
		ProvinceState: 'State/Province',
		ChineseAddress: 'Chinese address',
		EnglishName: 'English name',
		BillingCycleKey: 'Billing Cycle Key',
		Operator: 'operator',
		CompanyBillingAddress: 'Company billing address',
		Type: 'Type',
		Country: 'Country country',
		CityCity: 'City City',
		SocialUnifiedCreditCode: 'Social Unified Credit Code',
		Address: 'Detailed address',
		EnglishAddress: 'English Address',
		BusinessPeople: 'Business people.',
		Save: 'Save',
		Cancel: 'Cancel',
		Check: 'Check',
		Delete: 'Delete',
		Sure: 'Sure',
		Reset: 'Reset',
		Update: 'Update',
		AddToBlacklist: 'Add to blacklist',
		ConvertToForeignAgent: 'Convert to foreign agent',
		Label: 'Label',
		SupplierInformationAttachment: 'Supplier Information Attachment',
		BasicInformation: 'Basic Information',
		ContactInformation: 'Contact information',
		GeneralInformation: 'General Information',
		SupplierRemarks: 'Supplier Remarks',
		AddContactInformation: 'Add contact information',
		AddEnglishProductName: 'Add English product name',
		AddMark: 'Add shipping mark',
		CooperationStatus: 'Cooperation Status',
		BillingCycleMonthly: 'Billing cycle monthly',
		startTime: 'startTime',
		endTime: 'endTime',
		to: 'to',
		qsrbq:'Please enter the label',
		qsrzq:'Please enter the account period',
		qxz:'Please select',
		restore:'restore',
		bjck:'Edit View',
		operation:'operation',
		id:'serial number',
		tagName:'tag name',
		LabelType:'label type',
		AddNewLabel:'Add new label',
		default:'default',
		Supplier:'supplier',
		qsrts:'Please enter the number of days',
		Area:'area',
		srgysgjzss:'Enter supplier keyword search',
		BusinessLicense:'Business License',
		grmc:'personal name',
		qymc:'Company name',
		Position:'position',
		Time:'time',
		lxdh:'Contact number',
		lxyx:'Contact email',
		scxz:'Upload limit (number of items)',
		Creator:'creator',
		Name: 'Name',
	},
	setting: {//配置管理
		ID: 'Id',
		UserName: 'UserName',
		Name: 'Name',
		Department: 'Department',
		Mail: 'Mailbox',
		Post: 'post',
		State: 'State',
		Operate: 'Operation',
		Search: 'Search',
		AddUser: 'Add User',
		RoleDescription: 'Role Description',
		RoleName: 'role name',
		DirectSuperior: 'Direct Superior',
		IndirectSuperior: 'Indirect Superior',
		CharacterName: 'Character Name',
		Edit: 'Edit',
		Add: 'Add new',
		Cancel: 'Cancel',
		Check: 'Check',
		Save: 'Save',
		Delete: 'Delete',
		Sure: 'OK',
		Reset: 'Reset',
		Update: 'Update',
		PermissionAssignment: 'Permission Assignment',
		AddRole: 'Add role',
		UploadTemplate: 'Upload Template',
		jwjtccc: 'Drag the file here, or click to upload',
		FileName: 'File Name',
		Download: 'Download',
		MenuName: 'Menu Name',
		MenuNameEn: 'MenuNameEn',
		ParentNode: 'parent node',
		RoutingAddress: 'routing address',
		MenuIcon: 'menu icon',
		ComponentAddress: 'Component address',
		Type: 'Type',
		Hide: 'Hide',
		Sort: 'Sort',
		Field: 'Field',
		ModuleName: 'Module Name',
		TableName: 'table name',
		Width: 'width',
		Fixed: 'Fixed',
		FieldOrder: 'Field Order',
		jyzgnbtc: 'Jin Ling is in charge of the internal commission rate',
		jyzgwbtc: 'Jin Yi is in charge of the external commission rate',
		dztc: 'document commission rate',
		klcztcl: 'FOREST operation commission rate',
		jyxswbjc: 'Jinyi sales external basis commission rate',
		jyxswblb: 'Jin Yi sells external double basic salary commission rate',
		jyxssb: 'Jin Yi sells three times the basic salary commission rate',
		jyxsnb: 'Jinyi sales internal profit margin',
		ColinCommissionRate: 'FOREST commission rate',
		jywbtc: 'Jinyi total external commission',
		jynbtc: 'Jinyi total internal commission',
		bdswjl: 'BD business manager profit',
		TaxRatio: 'Tax Ratio',
		USDollarCurrency: 'US dollar exchange rate',
		Years: 'Year Month',
		BusinessName: 'Business Name',
		BasicSalary: 'Basic Salary',
		Founder: 'Creator',
		CreationTime: 'Creation time',
		LastModifiedBy: 'Last Modified By',
		LastModified: 'Last Modified Time',
		Base: 'Basic',
		TableColumn: 'table column',
		BatchEdit: 'Batch Edit',
		Logo: 'Logo',
		EnglishName: 'English name',
		AssigningRoles: 'Assigning roles',
		FieldSync: 'Field Synchronization',
		MenuPermissions: 'Menu Permissions',
		RolePermissionSettings: 'Role Permission Settings',
		UserLoginName: 'User Login Name',
		LoginPassword: 'Login password',
		Password: 'Password',
		Telephone: 'Phone',
		HeaderName: 'header name',
		NoData: 'No data yet',
		RoleType: 'role type',
		qsr: 'Please enter',
		qsryhm: 'Please enter a username',
		qsrxm: 'Please enter your name',
		qsrbm: 'Please enter the department',
		qsrcdmc: 'Please enter the menu name',
		qsrmkmc: 'Please enter the module name',
		qsrbgmc: 'Please enter the form name',
		qsrbtmc: 'Please enter the header name',
		qsrwybsl: 'Please enter a unique ID',
		qsrzdsx: 'Please enter the field order',
		qsrkd: 'Please enter the width',
		qsrywmc: 'Please enter the English name',
		qsrmc: 'Please enter a name',
		qsrzwmsbt: 'Please enter the Chinese description title',
		qsrzd: 'Please enter the field',
		qxzjslx: 'Please select a character type',
		user_area: 'Type of code',
	}, knowledge: {//金翊
		Edit: 'Edit',
		Add: 'Add new',
		Cancel: 'Cancel',
		Check: 'Check',
		Save: 'Save',
		Delete: 'Delete',
		Sure: 'OK',
		Reset: 'Reset',
		Update: 'Update',
		Develop: 'Develop',
		TitleName: 'Title Name',
		Category: 'Category',
		FileName: 'File Name',
		FileCategory: 'File Category',
		FilePath: 'File Path',
		FileSize: 'File size',
		CreationTime: 'Creation time',
		VideoTitle: 'Video Title',
		PictureName: 'picture name',
		ImageSize: 'Image size',
		VideoName: 'Video Name',
		VideoCategory: 'Video Category',
		VideoPath: 'Video path',
		VideoSize: 'Video size',
		ShippingTreasureBox: 'Shipping Treasure Box',
		ShippingKnowledge: 'Shipping knowledge',
		ReferenceDocument: 'Reference Document',
		ExpandKnowledge: 'Extended knowledge',
		WebsiteName: 'Website Name',
		WebsiteAddress: 'Website Address',
		WebsiteLink: 'Website Link',
		WebsiteCover: 'Website Cover',
		VideoCover: 'Video cover',
		VideoUpload: 'Video upload',
		InformationCover: 'Information cover',
		FileUpload: 'File upload',
		UploadPicture: 'Upload Picture',
		qbkc: 'All courses',
		zxkc: 'latest course',
		More: 'More',
		jwjtdcc: 'Drag the file here, or <em>click to upload</em>',
		zbschpg: 'Only upload jpg/png files, and no more than 500kb',
		znscpdf: 'Only upload pdf files',
		znscMP4: 'Only upload MP4 files',
		znscpdfxlsx: 'Only upload .pdf and xlsx files',
	}, reportcenter: {//报表中心
		sjlysl: 'Number of sources of business opportunities',
		zchddsl: 'Total transaction order quantity',
		sjly: 'Source of business opportunities',
		xsgczb: 'Sales process indicator',
		syycjly: 'All transaction sources',
		syycjdd: 'All completed orders',
		cjddzl: 'Total amount of transaction orders',
		Total: 'Total',
		Indivual: 'Indivual',
		Common: 'Common',
		Amount: 'Amount',
		jyywtj: 'Jinyi Business Statistics',
		gryjzpm: 'Personal performance total ranking',
		klywtj: 'FOREST Business Statistics',
		zcje: 'total turnover',
		ddcjpm: 'order transaction ranking',
		zwq: 'Note: M—ten thousand k—thousand',
		klywbm: 'FOREST Business Unit',
		Collin: 'Kelin Department',
		MonthlyStatistics: 'Monthly Statistics',
		BusinessRanking: 'Business Ranking',
		Salesman: 'Salesman',
		TransactionOrderNumber: 'Transaction Order Number',
		TheMonthlyTurnover: 'The monthly turnover (RMB)',
		YearMonth: 'Year Month',
		YearStatistics: 'Year Statistics',
		TheAnnualTurnover: 'The annual turnover (RMB)',
		Year: 'Year',
		YearCurrencyStatistics: 'Year Currency Statistics',
		grsqbztj: 'Statistics of currency received by individuals',
		Currency: 'Currency',
		NumberOfCurrencies: 'Number of currencies',
		UnconvertedAmount: 'Unconverted amount',
		AMOUNT: 'AMOUNT',
		VOL: 'VOL',
		CompletedOrder: 'Completed order--RMB',
		BusinessReport: 'Business Report',
		AccumulatedThisYear: 'accumulated this year',
		AnnualProportion: 'Annual Proportion',
		MainIncome: 'main income',
		MainOperatingCost: 'Main Operating Cost',
		yjyf: 'Performance/Month',
		ColinCommission: 'FOREST Commission',
		GrossProfit: 'Gross Profit',
		SalaryCost: 'Salary cost',
		JinyiTax: 'Jinyi Tax',
		OtherCosts: 'Other costs',
		NetProfit: 'Net Profit',
		ActuallyPaid: 'actually paid',
		Received: 'Received',
		Handle: 'Handle',
		Receivable: 'Receivable',
		Unpaid: 'Unpaid',
		Profit: 'Profit',
		FeeMonthlyTotalPrice: 'Fees monthly total price',
		jyddmx: 'Consolidation order details',
		jynbddmx: 'Consolidated internal order details',
		jywbddmx: 'Consolidated external order details',
		Box: 'Box Type',
		jyddhyxx: 'Consolidation order shipping box type',
		ShippingTime: 'Shipping time',
		To: 'to',
		StartDate: 'Start Date',
		EndDate: 'End Date',
		hyxxfhsjtj: 'Statistics of shipping time for shipping boxes',
		Volume: 'Volume',
		Shipping: 'Shipping',
		LandTransport: 'Land Transport',
		AirTransport: 'Air Transport',
		IronTransport: 'RAIL Transport',
		CrossBorderPackage: 'cross-border package',
		ThisYear: 'ThisYear',
		Season: 'Season',
		Month: 'Month',
		Today: 'Today',
		PerYear: 'PerYear',
		jyddhytj: 'Consolidation order shipping volume',
		jyddlytj: 'Consolidation order land volume',
		jyddkytj: 'Consolidation order air volume',
		jyddkjbgtj: 'Cross-border parcel volume of consolidation order',
		jyddtytj: 'Consolidation order iron volume',
		jytjfhsjtj: 'Statistics of shipping volume and shipping time',
		IndexMonth: 'Index/Month',
		SalesGrossMargin: 'Sales Gross Margin',
		szmll: 'Revenue and expenditure gross profit margin',
		ReturnOnCapital: 'Return On Capital',
		FundStatement: 'Fund statement (receivable)',
		FundStatementPayable: 'Statement of Funds (Payable)',
		OrderName: 'Order Name',
		Uncollected: 'Not received',
		Forwarder: 'Forwarder',
		ConsolidationType: 'Consolidation type',
		szyk: 'Revenue and expenditure profit and loss (real payment)',
		dcczyk: 'Export revenue and expenditure profit and loss (real payment)',
		Income: 'income',
		Expenditure: 'Expenditure',
		State: 'State',
		Offeror: 'Offerer',
		JinyiSales: 'Jinyi Sales',
		Client: 'Client',
		AccountPeriod: 'account period',
		SalesProfitAndLoss: 'Sales profit and loss (receivable and payable)',
		ExportSalesProfitAndLoss: 'Export sales profit and loss (receivable and payable)',
		ExportAllFundsReportPayable: 'Export all funds report (payable)',
		TotalProfit: 'Total Profit',
		TotalExpenses: 'Total Expenses',
		TotalRevenue: 'Total Revenue',
		gysfyytj: 'Monthly Statistics of Supplier Expenses',
		YearAndMonthStatistics: 'YearAndMonthStatistics',
		BusinessType: 'Business Type',
		CustomerName: 'Customer Name',
		zdyhxje: 'Amount that has been written off on the bill',
		qxzywy: 'Please select a salesperson',
		qxzzsqjm: 'Please select the top few to display',
		qxzy: 'Please select a month',
		qnzn: 'Please select a year',
		qnzgys: 'Please select a supplier',
	}
	, app: {//金翊app
		appbbgx: 'App version update',
		CreateUser: 'Feedback Nickname',
		CreateTime: 'Feedback time',
		Remark: 'Feedback problem',
		Remarks: 'APP update content',
		Title: 'APP update title',
		Cancel: 'Cancel',
		Save: 'Save',
	}
	, userRegister: {//注册
		zcxzh: '注册新账号',
		sjoryxyz: '手机/邮箱验证',
		xxxx: '详细信息',
		wczc: '完成注册',
		Remarks: 'APP更新内容',
		Title: 'APP更新标题',
		Cancel: '取消',
		Save: '保存',
	}
	, online_order: {//在线下单
		consignee: 'Consignee',
		shipper:'Shipper',
		xzlb:'Select category',
		zybxxznsxlb:'Note: You must first select the category you want',
		SmartFill:'Smart fill',
		addressBook:'address book',
		bcddzp:'Save to address book',
		copy:'copy',
		orderNumber:'Order Number',
		company:'company',
		FixedTelephone:'Fixed telephone',
		City:'City',
		Address:'Address',
		sender:'sender',
		Receiver:'Receiver',
		phoneNumber:'phone number',
		emailAddress:'email address',
		PickupContact:'Pickup Contact',
		qhlxrsj:'Pickup contact phone number',
		qhlxrdh:'Pickup contact telephone',
		cargoInformation:'cargo information',
		GoodTime:'Good time',
		Consignment:'Consignment',
		termsOfTrade:'terms of trade',
		Box:'Box',
		totalNumber:'total number',
		piece:'piece',
		ygzzl:'Estimated total weight',
		GoodsSize:'GoodsSize',
		Order:'Order',
		ProductName:'Product name',
		ddsl:'quantity of order',
		ygmz:'Estimated gross weight',
		BookingVolume:'Booking volume',
		ygjs:'Estimated number of pieces',
		operate:'operate',
		WaybillRemarks:'Waybill remarks',
		qxd:'Book Shipping',
		postode:'PostCode',
		dzznq:'Address paste area',
		dzznqnr:'Paste the address information and press Enter. For example: Zhang San 13666666666 Wanlecheng Plaza, Wujing Town, Minhang District, Shanghai',
		fhxx:'DeliveryInformation',
		refresh:'Refresh',
		Selectaddress:'SelectAddress',
		Receiptofgoods:'ReceiptOfGoods',
		deliveryinformation:'DeliveryInformation',
		closure:'Closure',
		Sure:'Sure',
		xzjfxx:'AddSenderInformation',
		sfswmr:'WhetherToSetAsDefault',
		default:'Default',
		notspecified:'NotSpecified',
		submit:'Submit',
		hhrqsj:'DeliveryDateAndTime',
		date:'Date',
		period:'Period',
		xzhhsj:'ChooseAGoodTimeForDelivery',
		Cancel:'Cancel',
		ddqr:'Order Confirmation',
		xzsfxx:'Add recipient information',
		ggsybhhw: 'Your company has prepared the goods, and the time has passed when the brother comes to pick up the goods smoothly',
		shtz:'Notice later',
		orderid:'orderid',
		quantity:'quantity',
		price:'price',
		totalprice:'total price',
		dctj:'booking volume',
		ddid:'Please enter the waybill remarks',
		Delete: 'Delete',
		SendingCompany: 'Sending Company',
		Sender: 'Sender',
		jjsjhm: 'Sending mobile phone number',
		jjyx: 'Sending email',
		jjgddh: 'Sending landline number',
		jjxxdz: 'Detailed shipping address',
		qhlxr: 'Pickup contact',
		qhsjhm: 'Pickup phone number',
		qhgddh: 'Landline number for picking up goods',
		chgs: 'receiving company',
		Consignee: 'Consignee',
		chsjhm: 'receiving mobile phone number',
		chyx: 'delivery email',
		shgddh: 'Receipt landline number',
		ReceivingCity: 'ReceivingCity',
		shxxdz:'Detailed delivery address',
		zjs: 'Total number of pieces',
		CargoVolume: 'cargo volume',
		Creator: 'creator',
		OrderCategory: 'Order Category',
		ShippingTerms: 'Shipping Terms',
		remark: 'remark',
	}
	,
	AibabaPlatform: {//阿里巴巴平台
		platform_name: 'Platform name',
		order_number:'Order number',
		order_money:'Order amount',//订单金额
		destination_country:'Destination country',
		overdue_time:'Overdue time',
		client_name:'Client name',
		delivery_type:'Shipping form',
		brushing_real_orders:'Brush/actual order',
		principal:'Principal',
		states:'State',
		salesmen:'Salesman',
		order_name:'Order name',
		pi:'PI',
		develiver_money:'Shipping amount',
		moving_time:'Moving time',
		moving_amount:'Moving order amount',
		make_payment:'Make payment',
		estimated_transaction_fees:'Estimated transaction fees',
		payment_time:'Payment time',
		container_number:'Container number',
		cz:'Operate',
		currency:'Currency',
		lr:'Enter',
		hbfh:'Combined shipping',
		qxhb:'Unmerge',
		hbfk:'Consolidate payments',
		dlz:'Agency certificate',
		kpzl:'Billing Information',
		fp:'Bill',
		qxz:'please choose ',
		qsrgjzw:'Please enter the country Chinese',
		ddxx:'Order information',
		fhxx:'Delivery information',
		baocun:'save',
		xiugai:'sevise',
		wanjie:'end',
		jwjtdcc: 'Drag the file here, or <em>click to upload</em>',
		guanbi: 'closure',
		di:'No.',
		tiao:'strip',
		fuzhi:'copy',
		shanchu:'delete',
		checkc:'check',
		delivery_info:'delivery information',
		zxg:'Modify only orders',
		searchshow:'inquire',
		recover:'recover',
		platformtype:'look up',
	}
}
